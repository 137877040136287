import { Reducer } from 'redux';

import { LocaleInfos, Locales } from './Constants';
import { I18nActionTypes, I18N, I18NState } from './Types';

const initialState: I18NState = {
    i18n: {
        _: (msgID: string) => msgID.toUpperCase(),
        gettext: (msgID: string) => msgID.toUpperCase(),
        ngettext: (msgID: string, plural: string, n: number) => ((n > 1) ? plural.toUpperCase() : msgID.toUpperCase()),
        sprintf: (format: string, ...theArgs: any[]) => format,
    },
    localeInfos: LocaleInfos[Locales.French],
};

export let exportedI18n: I18N = initialState.i18n;

const reducer: Reducer<I18NState> = (state = initialState, action) => {
    switch (action.type) {
        case I18nActionTypes.SET_LOCALE_SUCCESS: {
            exportedI18n = action.payload.i18n;
            return {
                ...state,
                i18n: action.payload.i18n,
                localeInfos: action.payload.localeInfos,
            };
        }
        case I18nActionTypes.SET_LOCALE_ERROR: {
            return { ...state, errors: action.payload };
        }
        default: {
            return state;
        }
    }
};

export { reducer as I18NReducer };
