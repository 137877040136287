export enum IUserActionTypes {
    LOGIN = '@@user/LOGIN',
    LOGOUT = '@@user/LOGOUT',
    LOGIN_SUCCESS = '@@user/LOGIN_SUCCESS',
    LOGIN_ERROR = '@@user/LOGIN_ERROR',
    GET_TOKEN = '@@user/GET_TOKEN',
    GET_TOKEN_SUCCESS = '@@user/GET_TOKEN_SUCCESS',
}

export interface IResults {
    readonly expires: number;
    readonly token: string;
    readonly user: any;
}
export interface IUserInfo {
    readonly email: string;
    readonly firstname: string;
    readonly id: number;
    readonly name: string;
    readonly position: string | null;
}
export const initialUserInfo: IUserInfo = {
    email: "",
    firstname: "",
    id: 0,
    name: "",
    position: null,
};
export interface IUserState {
    readonly username: string;
    readonly token: string;
    readonly expires: Date;
    readonly userInfo: IUserInfo;
    readonly tokenPlayer: string;
    readonly errorMessage:any
}
