import * as effects from 'redux-saga/effects';

import { LayoutActionTypes } from './Types';
//
// import { Logger } from '../../Utils/Logger';

function handleDrawerChanges(visible: boolean) {
    // if (visible) {
    //     Logger.debug('Visible');
    // } else {
    //     Logger.debug('PAS Visible');
    // }
}

function* watchSidebarChanges() {
  yield effects.takeEvery(LayoutActionTypes.DRAWER_SHOW, handleDrawerChanges.bind(null, true));
  yield effects.takeEvery(LayoutActionTypes.DRAWER_HIDE, handleDrawerChanges.bind(null, false));
}

export function* LayoutSaga() {
  yield effects.all([effects.fork(watchSidebarChanges)]);
}
