import * as MD from '@material-ui/core';
import * as React from 'react';
import { $CombinedState, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  DatePicker
} from '@material-ui/pickers';
import { IApplicationState, IConnectedReduxProps } from '../../Store';

import { ILayoutState } from '../../Store/Layout/Types';

import { I18N, ILocaleInfos } from '../../Store/I18n/Types';
import { ButtonDensed } from '../../Themes/StyledElements';

import { RouterState } from 'connected-react-router';
import moment from 'moment';
import { getDateSevenDaysBeforeTodayHourZero, getNextWeekDay, toLocaleDateString } from '../../Utils/Time';
import { PaletteMode } from '../../Themes';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../Utils/Various';
import { getClassementClub, getHits, saveSelectedDateWeekNumber, sendEmailNewsLetter } from '../../Store/ClassementClub/Actions';
import { generateFileXML } from '../../Store/GenerateFile/Actions';

import ClearIcon from '@material-ui/icons/Clear';
import { IClassementClubState } from '../../Store/ClassementClub/Types';

interface IPropsFromState {
    i18n: I18N;
    layout: ILayoutState;
    localeInfos: ILocaleInfos;
    router: RouterState;
    classementClub: IClassementClubState;
}

interface IState {
  selected_date: Date | null;
  date_range: Date[];
  week_number: number;
  email: string;
  isValidEmail: boolean | null;
  date_cloture: Date | null;
}

interface IPropsTheme {
  theme: any;
}

interface IPropsFromDispatch {
  getClassementClub: typeof getClassementClub;
  generateFileXML: typeof generateFileXML;
  sendEmailNewsLetter: typeof sendEmailNewsLetter;
  getHits: typeof getHits;
  saveSelectedDateWeekNumber: typeof saveSelectedDateWeekNumber;

}

type AllProps =
    MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IPropsTheme &
    IConnectedReduxProps;

const styles = (theme: any) => MD.createStyles({
    drawerPaper: {
        width: theme.Club40.dimensions.drawerWidth,
        paddingTop: '14px',
        paddingLeft: '15px',
        paddingRight: '15px',
        backgroundColor: theme.palette.background.default
    },
    launchSearch: {
      marginTop: '20px'
    },
    infos:{
      fontSize: '16px',
      fontWeight: 600,
      backgroundColor: theme.palette.background.paper,
      display: 'block',
      padding: '10px 10px 5px',
      marginBottom: '10px',
      position: 'fixed',
      top: 40,
      left: 0,
      width: '100%',
      zIndex: 1201
  },
  drawer: {
    '&.hidden': {
        width: '0px',
    },
    flexShrink: 0,
},
form: {
  marginTop:'-20px',
  display: 'flex',
  flexFlow: 'row',
  flexWrap: 'wrap',
  paddingTop: 100
},
marginEmail: {
  width: '100%',
  marginTop: "20px",
  marginBottom:"-1px"
},
clearIcon:{
  fontSize:'smaller',
  marginRight:'-10px'
},
iconButton:{
  "&:hover": {
      backgroundColor: "transparent"
    },
  color:theme.palette.primary.main
},
inputLabel:{
  marginTop:'-2px',
  fontFamily: 'Lato',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  letterSpacing: '0px',
  textAlign: 'left',
  color: theme.palette.text.primary
},
});

export class ClassementDatesSelectComponent extends React.Component<AllProps, IState> {

    public constructor(props: AllProps) {
      super(props);
      this.state = {
        selected_date: null,
        date_range: [],
        week_number: 1,
        email: '',
        isValidEmail: null,
        date_cloture: null
      }
    }

    static getDerivedStateFromProps(props: AllProps, state: IState) {
      if (state.date_cloture === null && props.classementClub.date_cloture !== null) {
        state.date_cloture = props.classementClub.date_cloture;
        let dateString  = state.date_cloture;
        //let minDate = new Date("2011-12-09");//Fri Dec 09 2011 01:00:00 GMT+0100 (heure normale d’Europe centrale)
        let minDateObject = moment("09/12/2011", "DD/MM/YYYY"); 
        let minDate = minDateObject.toDate();
        console.log("===minDate ", minDate)
        //let today = Date(state.date_cloture);//getDateSevenDaysBeforeTodayHourZero(new Date());
        let dateMomentObject = moment(dateString, "DD/MM/YYYY"); 
        var date_cloture = dateMomentObject.toDate();
        console.log("===date_cloture ", date_cloture)
        let dates:Date[] = [];
        
        let date = minDate;
        while (date <= date_cloture) {
          dates.push(date);
          date = getNextWeekDay(date);
        }

        dates = dates.reverse();
        state.date_range = dates;
        state.selected_date = dates[0];
        state.week_number = moment(dates[0], "MM-DD-YYYY").add(6, 'days').week()
       
      }
      return state;
    }

    public componentDidMount() {
      /* let minDate = new Date("2011-12-09");//Fri Dec 09 2011 01:00:00 GMT+0100 (heure normale d’Europe centrale)
      let today = getDateSevenDaysBeforeTodayHourZero(new Date());
      
      let dates:Date[] = [];
      
      let date = minDate;
      while (date < today) {
        dates.push(date);
        date = getNextWeekDay(date);
      }

      dates = dates.reverse();
      this.setState({
        date_range: dates,
        selected_date: dates[0],
        week_number: moment(dates[0], "MM-DD-YYYY").add(6, 'days').week()
      })
      //this.props.getClassementClub(dates[0]); */
      
    }

    private onDateChange(e:any) {
      const week_number = moment(e.target.value, "MM-DD-YYYY").add(6, 'days').week();

      this.setState({
          selected_date: e.target.value,
          week_number: week_number
      })
      
      this.props.saveSelectedDateWeekNumber(e.target.value, week_number);
      this.props.getClassementClub(e.target.value);
    }


    public render(): React.ReactNode {
        const { classes, layout } = this.props;
        if (this.state.selected_date !== null) {
          return (
            <MD.Select
            variant='outlined'
            style={{width: '100%'}}
            id="month"
            value={this.state.selected_date}
            onChange={(ev:any) => this.onDateChange(ev) as any}
            renderValue={(value:any) => {
              let date = this.state.date_range.filter(x => x === value)[0];
              return moment(date).format("DD/MM/YYYY");
            }}
          >
              {
                  this.state.date_range && this.state.date_range.length > 0 && this.state.date_range.map((x:any) => {
                      return <MD.MenuItem key={x} value={x}>
                        {  moment(x).format("DD/MM/YYYY") }
                        </MD.MenuItem>
                  })
              }
          </MD.Select>
          );
        } else {
          return null
        }
        
    }
}

const mapStateToProps = ({ i18n, layout, router, classementClub }: IApplicationState) => ({
  i18n: i18n.i18n,
  localeInfos: i18n.localeInfos,
  layout,
  router,
  classementClub
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getClassementClub: (date_current_cloture: Date) => dispatch(getClassementClub(date_current_cloture)),
  generateFileXML: (date_current_cloture: Date) => dispatch(generateFileXML(date_current_cloture)),
  sendEmailNewsLetter: (email:string) => dispatch(sendEmailNewsLetter(email)),
  getHits: (date_current_cloture: Date) => dispatch(getHits(date_current_cloture)),
  saveSelectedDateWeekNumber: (date: Date, week_number: number) => dispatch(saveSelectedDateWeekNumber(date, week_number))

});

export const ClassementDatesSelect =
  connect(mapStateToProps, mapDispatchToProps)(MD.withTheme(MD.withStyles(styles)(ClassementDatesSelectComponent)));
