import * as React from 'react';

import {
    Box,
    Card,
    Typography,
    Container,
    Divider,
    Button,
    FormControl,
    OutlinedInput,
    InputAdornment,
    Grid,
    Link
  } from '@material-ui/core';
  
import styled from "styled-components";
  
  const MainContent = styled(Box)(
    ({ theme }) => `
      height: 100%;
      display: flex;
      flex: 1;
      overflow: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  `
  );
  
  const OutlinedInputWrapper = styled(OutlinedInput)(
    ({ theme }) => `
      background-color: ${theme.colors.alpha.white[100]};
  `
  );
  
  const ButtonSearch = styled(Button)(
    ({ theme }) => `
      margin-right: -${theme.spacing(1)};
  `
  );
  
  function LiensPartenaires() {
    return (
      <>
        <MainContent>
          <Container maxWidth="md" style={{marginTop: "50px"}}>
            <Box textAlign="center">
              <Typography variant="h2" >
                Liens partenaires du club 40
              </Typography>
            </Box>
            <Container >
              <Card style={{ textAlign: 'center', marginTop: "50px"}}>
                <Grid container direction="column">
                    <Grid item xs>
                        <Grid container direction="row">
                            <Grid item>
                              <Link
                              target="_blank"
                              href={"https://snepmusique.com/"}
                              >
                                <img
                                  alt="BTC"
                                  src="/images/liens-partenaires/SNEP_Logo.png"
                                  height={"100px"}
                                  width={"240px"}
                                  />
                              </Link>
                            
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                    <Grid container direction="row">
                            
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Container>
          </Container>
        </MainContent>
      </>
    );
  }
  
  export default LiensPartenaires;
  