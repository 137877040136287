import * as React from 'react';

import { Box, Container, Link, Typography, Card, CardHeader } from '@material-ui/core';

import styled from "styled-components";



function Footer() {
  return (
    <Container className="footer-wrapper" style={{marginTop: 50, paddingLeft: 10}}>
      <Box
        pb={4}
        display={{ xs: 'block', md: 'block' }}
        alignItems="center"
        textAlign={{ xs: 'center', md: 'left' }}
        justifyContent="space-between"
        style={{    fontFamily: "verdana",
            color: "rgb(118, 118, 118)",
            fontSize: "small", fontStyle:'italic'}}
      >
        <Box >
          <Typography variant="h5" style={{fontSize: 16}}>
            {"Note méthodologique"}
          </Typography>
          <Typography variant="subtitle1" style={{fontSize: 14}}>
            Classement établi à partir de la programmation réelle des discothèques, des clubs et des BAMs du Panel YACAST.
            Les enregistrements sont effectués sur "boîtes noires" sécurisées.
            Le panel Club40 est composé des discothèques positionnées sur la diffusion de nouveautés.
            Les informations publiées dans ce classement n'ont pas valeur de certification de diffusion en matière de répartition de droits d'auteur et de droits voisins.
          </Typography>
        </Box>
        <Box style={{marginTop: "10px"}}>
          <Typography variant="h5" style={{fontSize: 16}}>
            {"Abonnement"}
          </Typography>
          <div>
            <Typography variant="subtitle1" style={{fontSize: 14}} >
              Pour recevoir chaque semaine la Newsletter : <Link
              href="mailto:club40@yacast.fr?Subject=Register"
              target="_blank"
              rel="noopener noreferrer"
            >
              cliquez ici
            </Link>
            </Typography>
          </div>
          <div>
          <Typography variant="subtitle1" style={{fontSize: 14}}>
          Pour vous désabonner : <Link
              href="mailto:club40@yacast.fr?Subject=Unregister"
              target="_blank"
              rel="noopener noreferrer"
            >
              cliquez ici
            </Link>
            </Typography>
          </div>
        </Box>
        <Box style={{marginTop: "10px"}}>
          
          <div>
            <Typography variant="subtitle1" style={{fontSize: 14}}>
            Toute reproduction autorisée avec mention "Club 40 - Classement officiel des meilleures diffusions musicales en Clubs" - sauf à des fins commerciales
            </Typography>
          </div>
        </Box>
        {/* <Typography
          sx={{
            pt: { xs: 2, md: 0 }
          }}
          variant="subtitle1"
        >
          Crafted by{' '}
          <Link
            href="https://bloomui.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            BloomUI.com
          </Link>
        </Typography> */}
      </Box>
    </Container>
  );
}

export default Footer;
