import { action } from 'typesafe-actions';

import { NotificationBarActionTypes } from './Types';

export const setError =
  (error: Error | null) =>
    action(NotificationBarActionTypes.SET_ERROR, error);

export const setAuthError =
    (error: Error | null) =>
      action(NotificationBarActionTypes.SET_AUTH_ERROR, error);

export const setSuccessMessage =
  (successMessage: string | null) =>
    action(NotificationBarActionTypes.SET_SUCCESS_MESSAGE, successMessage);

export const setInfoMessage =
  (infoMessage: string | null) =>
    action(NotificationBarActionTypes.SET_INFO_MESSAGE, infoMessage);
