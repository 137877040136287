import { Reducer } from 'redux';

import { Backend } from '../../Backend/Backend';
import { Logger } from '../../Utils/Logger';
import { TypedStorage } from '../../Utils/TypedStorage';

import { initialUserInfo, IUserActionTypes, IUserState } from './Types';

const initialUser = TypedStorage.get('userName', '');
const initialUserTS = TypedStorage.get('userDate', 0);
const initialUserToken = TypedStorage.get('userToken', '');

let initialState: IUserState = {
    expires: new Date(0),
    token: '',
    username: '',
    userInfo: initialUserInfo,
    tokenPlayer:'',
    errorMessage:null
};

if ((initialUserToken !== '') && (initialUser !== '') && (initialUserTS !== 0)) {
    if (initialUserTS > Date.now()) {
        Logger.info('Reusing previous session');
        initialState = {
            ...initialState,
            expires: new Date(initialUserTS),
            token: initialUserToken,
            username: initialUser,
        };
        Backend.getInstance().setToken(initialUserToken);
    } else {
        Logger.info('Cancelling expired previous session');
        TypedStorage.set('userName', '');
        TypedStorage.set('userDate', 0);
        TypedStorage.set('userToken', '');
    }
}

const reducer: Reducer<IUserState> = (state = initialState, action) => {
    switch (action.type) {
        case IUserActionTypes.LOGIN: {
            return {
                ...state,
                errorMessage: null
            };
        }
        case IUserActionTypes.LOGOUT: {
            TypedStorage.set('userName', '');
            TypedStorage.set('userToken', '');
            TypedStorage.set('userDate', 0);
            return {
                ...state,
                expires: new Date(0),
                token: '',
                username: '',
            };
        }
        case IUserActionTypes.LOGIN_ERROR: {
            TypedStorage.set('userName', '');
            TypedStorage.set('userToken', '');
            TypedStorage.set('userDate', 0);
            return {
                ...state,
                expires: new Date(0),
                token: '',
                username: '',
                errorMessage: action.payload
            };
        }
        case IUserActionTypes.LOGIN_SUCCESS: {
            TypedStorage.set('userName', action.payload[0]);
            TypedStorage.set('userToken', action.payload[1]);
            TypedStorage.set('userDate', action.payload[2]);
            return {
                ...state,
                expires: new Date(action.payload[2]),
                token: action.payload[1],
                username: action.payload[0],
                userInfo: action.payload[3],
                errorMessage: null
            };
        }
        case IUserActionTypes.GET_TOKEN: {
            return {
                ...state,
                username: action.payload[2]
            }
        }
        case IUserActionTypes.GET_TOKEN_SUCCESS: {
            return {
                ...state,
                tokenPlayer: action.payload[1]
            }
        }
        default: {
            return state;
        }
    }
};

export { reducer as UserReducer };
