import { Reducer } from 'redux';
import { PaletteMode } from '../../Themes';
import { TypedStorage } from '../../Utils/TypedStorage';

import { ILayoutState, LayoutActionTypes } from './Types';

const initialTheme = TypedStorage.get('theme', PaletteMode.DARK);

const initialState: ILayoutState = {
    drawerContent: null,
    pageContent: null,
    showDrawer: true,
    theme: initialTheme,
};

const reducer: Reducer<ILayoutState> = (state = initialState, action) => {
    switch (action.type) {
        case LayoutActionTypes.DRAWER_SHOW: {
            return { ...state, showDrawer: true };
        }
        case LayoutActionTypes.DRAWER_HIDE: {
            return { ...state, showDrawer: false };
        }
        case LayoutActionTypes.SET_DRAWER_CONTENT: {
            return { ...state, drawerContent: action.payload };
        }
        case LayoutActionTypes.SET_PAGE_CONTENT: {
            return { ...state, pageContent: action.payload };
        }
        case LayoutActionTypes.TOGGLE_THEME: {
            const theme = state.theme === PaletteMode.DARK ? PaletteMode.LIGHT : PaletteMode.DARK;

            TypedStorage.set('theme', theme);
            return {
                ...state,
                theme: theme
            };
        }

        default: {
            return state;
        }
    }
};

export { reducer as LayoutReducer };
