import * as effects from 'redux-saga/effects';

import { Backend } from '../../Backend/Backend';
import { Logger } from '../../Utils/Logger';

import { login, loginError, loginSuccess, logout } from './Actions';
import { IUserActionTypes } from './Types';

function* handleLogin(action: ReturnType<typeof login>) {
    const backend = Backend.getInstance();
    try {
        const res = yield effects.apply(backend, backend.login, (action.payload as any));
        Logger.info('Successfully logged in');
        yield effects.put(loginSuccess(action.payload[0], res[0], res[1], res[2]));
        
        
    } catch (err) {
        Logger.warn({ err }, 'Failed login');
        yield effects.put(loginError(err as string));
    }
}

function* handleLogout(action: ReturnType<typeof logout>) {
    try {
        
        Logger.info('Successfully logged out');
    } catch (err) {
        Logger.warn({ err }, 'Failed logout');
        yield effects.put(loginError(err as string));
    }
}

function* watchLogin() {
    yield effects.takeEvery(IUserActionTypes.LOGIN, handleLogin);
    yield effects.takeEvery(IUserActionTypes.LOGOUT, handleLogout);
}

export function* UserSaga() {
    yield effects.all([
        effects.fork(watchLogin),
    ]);
}
