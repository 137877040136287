import {
    colors,
    createTheme ,
    Theme,
} from '@material-ui/core';

import WebFont from 'webfontloader';

interface ICustomThemeDimensionsProps {
    appBarHeight: number;
    drawerWidth: number;
}

interface ICustomThemeColorsProps {
    chipInvalid: string;
    chipInvalidText: string;
    chipValid: string;
    chipValidText: string;
    errorText: string;
    snackErrorBG: string;
    snackInfoText: string;
    snackInfoBG: string;
    snackErrorText: string;
    snackSuccessBG: string;
    snackSuccessText: string;
    accent: string;
}

interface ICustomThemeFontsProps {
    time: string;
}

interface ICustomThemeModalProps {
  maxWidth: string,
  backgroundColor: string,
  padding: string,
  border: string,
  borderRadius: string,
  outline: string
}

interface ICustomThemeProps {
    background: any;
    colors: ICustomThemeColorsProps;
    dimensions: ICustomThemeDimensionsProps;
    fonts: ICustomThemeFontsProps;
    modalContainer: ICustomThemeModalProps;
    modalNewListContainer: ICustomThemeModalProps;
}

interface ICustomProps {
    Club40: ICustomThemeProps;
    Hyperion: any;
}

export type Club40Theme = Theme & ICustomProps;

WebFont.load({
    google: {
        families: [
            'Lato:100,200,300,400',
            'Anonymous Pro:300,400,700',
            'Roboto:300,400,500',
            'monospace',
        ],
    },
});

export enum PaletteMode {
    DARK = 'dark',
    LIGHT = 'light'
};

export const getTheme = (paletteMode?: PaletteMode): Club40Theme => {
    const theme = createTheme ({
        palette: {
            // Used by `getContrastText()` to maximize the contrast between the background and
            // the text.
            contrastThreshold: 3,
            error: colors.red,
            primary: {
                main: "rgb(245, 0, 87)",
                light: "#C4C4C4",
                dark: "#202020"
            },
            secondary: {
                main: "#6561E4"
            },
            // Used to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.2,
            type: paletteMode,
            background: {
                default: paletteMode === PaletteMode.LIGHT ? '#FFFFFF' : '#202020',
                paper: paletteMode === PaletteMode.LIGHT ? '#EEEEEE' : '#202020'
            },
            text: {
                primary: paletteMode === PaletteMode.LIGHT ? '#202020' : '#C4C4C4',
                secondary: paletteMode === PaletteMode.LIGHT ? '#000000' : '#EEEEEE',
            }
        },
        typography: {
            fontFamily: '"Lato"',
        },
        overrides: {
            MuiTab: {
                wrapper: {
                  flexDirection:'row',
                },
            },
            MuiMenuItem:{
                root:{
                    paddingBottom:'0px',
                    fontSize:'medium'
                }
            },
            MuiOutlinedInput: {
                input: {
                    padding: '5px 14px'
                }
            },
            MuiInputLabel:{
                outlined:{
                    transform: 'translate(14px, 10px) scale(1)'
                },
                shrink:{
                    fontSize:'large'
                }
            },
            MuiFormLabel:{
                root:{
                    fontSize: 'inherit',
                    color:'rgb(255,255,255,0.9)',
                    fontStyle:'italic'
                }
            },
            MuiInputBase:{
                input:{
                    font: 'webkit-mini-control',
                    padding:'3px 0'
                }
            },
            MuiChip:{
                root:{
                    height:'20px'
                }
            },
            MuiFormControl:{
                root:{
                    marginBottom: '-6px'
                }
            },
            MuiSlider:{
                root:{
                    padding:'24px 0px 10px'
                }
            },
            MuiTableCell:{
                root:{
                    letterSpacing:'0',
                    fontSize: 'small',
                    fontWeight:'lighter',
                    padding:'6px'
                }
            },
            MuiCheckbox:{
                root:{
                    color: paletteMode === PaletteMode.LIGHT ? '#202020' : '#C4C4C4',
                    padding:'5px'
                }
            },
            MuiTablePagination:{
                toolbar:{
                    minHeight:'45px',
                },
                select:{
                    fontSize:'12px',
                    fontFamily:'sans-serif'
                },
                root:{
                    overflow:'none'
                },
                selectRoot:{
                    marginLeft:'0px',
                    marginRight:'30px'
                },
                actions:{
                    marginLeft:'0px'
                }
            },
            MuiTypography:{
                body1: {
                    fontSize: "13px"
                },
                body2:{
                    fontSize:'12px',
                    fontFamily:'sans-serif'
                },
                displayBlock:{
                    fontSize:'13px',
                    
                }
            },
            MuiTableHead:{
                root:{
                    display:'felx'
                }
            },
            MuiIconButton:{
                root:{
                    padding:'0px'
                },
                edgeEnd:{
                    marginRight:'0px'
                }
            }
        },
    });

    const custom: ICustomProps = {
        Club40: {
            background: {
                elem: "#515772"
            },
            colors: {
                chipInvalid: '#993336',
                chipInvalidText: '#fff',
                chipValid: '#4DCC78',
                chipValidText: '#000',
                errorText: 'red',
                snackErrorBG: '#993336',
                snackInfoText: '#fff',
                snackInfoBG: '#202020',
                snackErrorText: '#fff',
                snackSuccessBG: '#43a047',
                snackSuccessText: '#fff',
                accent: '#11b2dd'
            },
            dimensions: {
                appBarHeight: 0,
                drawerWidth: 400,
            },
            fonts: {
                time: "'Anonymous Pro', monospace",
            },
            modalContainer: {
              maxWidth: '60%',
              backgroundColor: '#555',
              padding: '15px 25px',
              border: '2px solid #000',
              borderRadius: '5px',
              outline: 'none'
            },
            modalNewListContainer: {
              maxWidth: '60%',
              backgroundColor: theme.palette.text.primary,
              padding: '15px 25px',
              border: '2px solid #000',
              borderRadius: '5px',
              outline: 'none'
            }
        },
        Hyperion: {
            colors: {
                chipInvalid: '#993336',
                chipInvalidText: '#fff',
                chipValid: '#4DCC78',
                chipValidText: '#000',
                chipWarning: 'orange',
                chipWarningText: '#000',
                errorText: 'red',
                snackErrorBG: '#993336',
                snackInfoBG: '#202020',
                snackErrorText: '#fff',
                snackInfoText: '#fff',
                snackSuccessBG: '#43a047',
                snackSuccessText: '#fff',
            },
            dimensions: {
                appBarHeight: 50
            },
            fonts: {
                time: "'Anonymous Pro', monospace",
            },
        }
    };
    return { ...custom, ...theme };
};
