export enum NotificationBarActionTypes {
  SET_AUTH_ERROR = '@@notificationbar/SET_AUTH_ERROR',
  SET_ERROR = '@@notificationbar/SET_ERROR',
  SET_SUCCESS_MESSAGE = '@@notificationbar/SET_SUCCESS_MESSAGE',
  SET_INFO_MESSAGE = '@@notificationbar/SET_INFO_MESSAGE',
}

export interface INotificationBarState {
  error: Error | null;
  autherror: Error | null;
  successMessage: string | null;
  infoMessage: string | null;
}
