export interface ILocaleInfos {
    // Long date format
    formatLongDate: string;
    // Long date & time format
    formatLongDateTime: string;
    // Long date & time format with seconds
    formatLongDateTimeWithSeconds: string;
    // Short date format
    formatShortDate: string;
    // Short date & time format
    formatShortDateTime: string;
    // Long date & time format with seconds and milliseconds
    formatLongDateTimeWithMilliSeconds: string;
    // react-flag-icon-css icon to use
    iconName: string;
    // Moment.js locale to use
    momentLocale: string;
    // Locale name
    name: string;
}

export enum I18nActionTypes {
    SET_LOCALE = '@@i18n/SET_LOCALE',
    SET_LOCALE_SUCCESS = '@@i18n/SET_LOCALE_SUCCESS',
    SET_LOCALE_ERROR = '@@i18n/SET_LOCALE_ERROR',
}

export interface I18N {
    _: (msgID: string) => string;
    gettext: (msgID: string) => string;
    ngettext: (msgID: string, msgIDPlural: string, n: number) => string;
    sprintf: (format: string, ...theArgs: any[]) => string;
}

export interface I18NState {
    readonly i18n: I18N;
    readonly localeInfos: ILocaleInfos;
}
