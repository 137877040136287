import { action } from 'typesafe-actions';

import { Locales } from './Constants';
import { I18nActionTypes, I18N, ILocaleInfos } from './Types';

export const setLocale = (locale: Locales) => action(I18nActionTypes.SET_LOCALE, locale);
export const loadSuccess = (
        i18n: I18N,
        localeInfos: ILocaleInfos,
    ) =>
    action(I18nActionTypes.SET_LOCALE_SUCCESS, { i18n, localeInfos });
export const loadError = (message: string) => action(I18nActionTypes.SET_LOCALE_ERROR, message);
