import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Theme } from '@material-ui/core/styles';

import styled from 'styled-components';
//Store
import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { I18N } from '../../Store/I18n/Types';
import { IClassementClub, IClassementClubState } from '../../Store/ClassementClub/Types';
import { CustomTooltip, DialogTitle, PaperComponent, TableCell, TableHeaderCell } from '../../Themes/StyledElements';
import PauseCircleOutlineOutlinedIcon from '@material-ui/icons/PauseCircleOutlineOutlined';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import { ILayoutState } from '../../Store/Layout/Types';
import { Avatar, Box, Card, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';
import { changePochetteFilepath } from '../../Store/ClassementClub/Reducer';

import { withStyles, createStyles } from '@material-ui/core/styles';
import { isNullOrUndefined } from '../../Utils/Various';

const styles = (theme: Theme) => MD.createStyles({
  paperOverflow: {
    background: 'none',
    boxShadow: 'none'
  },
  tableContainer: {
      padding: '0px',
      //overflow: 'auto',
      //maxHeight: 'calc(100vh - 110px)',
  },
  rootHead: {
    width: '100%',
    display:'inline-table',
    position:'sticky',
    top:'0px',
    zIndex:1
  },
  playIcon: {
    cursor: 'pointer', marginRight: '10px', color: theme.palette.secondary.main
  },
});
export const StyledGrid = styled(MD.Grid)`
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#0e0e0e), color-stop(80%,#595757), color-stop(100%,#767272));

`;
interface IPropsFromState {
  i18n: I18N;
  classementClub: IClassementClubState;
  layout: ILayoutState;
}

interface IPropsTheme {
  theme: any;
}

interface IPropsFromDispatch {
}

type AllProps =
    MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    IPropsTheme &
    IConnectedReduxProps;


interface IState {
  currentTrackPlaying: any,
  openAudio: boolean;
  isPlaying: boolean;
}

export class UpcomingHitsComponent extends React.Component<AllProps, IState> {
    private audioPlayerRef: React.RefObject<HTMLAudioElement>
    public constructor(props: AllProps) {
        super(props);
        this.audioPlayerRef = React.createRef();
        this.state = {
          currentTrackPlaying: {filepath: '', id: 0},
          openAudio: false,
          isPlaying: false
        };
    }

    public componentDidMount() {
    }

    static getDerivedStateFromProps(props: AllProps, state: IState) {
      return state;
    }

    private  drawHitElem(elem:any, entree:boolean) {
  
      return (
        <Grid xs={12} sm={12} md={6} item>
              <MD.Grid container style={{display: "inline-flex"}}>
                <MD.Grid item xs={2} style={{marginRight: 10}} >
                  <img
                  style={{height: '60px'}} 
                    alt={elem.referencetitre}
                    src={entree ? "/images/meilleure-entree.gif" : "/images/meilleure-progression.gif"}
                  />
                </MD.Grid>
                <MD.Grid item xs={2} style={{marginRight: 10}}>
                  <img
                  style={{height: '60px'}} 
                    alt={elem.referencetitre}
                    src={changePochetteFilepath(elem.referencetitre)}
                  />
                </MD.Grid>
                <MD.Grid item xs style={{marginRight: 10}}>
                <Typography component="div">
                  {elem.nominterprete}
                </Typography>
                <Typography component="div">
                  {elem.titremusical}
                </Typography>
                <Typography component="div">
                  {elem.nomdistributeur}/{elem.nomlabel}
                </Typography>
                <Typography component="div">
                  {entree ? `${('Entree directe à la ')} ${elem.rangactuel}${"e"} ${('place')}`  : `+${elem.evorang}`}
                </Typography>
                </MD.Grid>
                {/* <MD.Grid item xs={8} style={{margin: 5, alignSelf: 'center'}}>
                  <Typography variant="h5" noWrap>
                    {entree ? ('Meilleure entrée du top' ) : ('Meilleure progression du top' )}
                  </Typography>
                </MD.Grid> */}
              </MD.Grid>
              {/* <Box
                sx={{
                  pt: 3
                }}
              >
                <Typography component="div">
                  {elem.nominterprete}
                </Typography>
                <Typography component="div">
                  {elem.titremusical}
                </Typography>
                <Typography component="div">
                  {elem.nomdistributeur}/{elem.nomlabel}
                </Typography>
                <Typography component="div">
                  {entree ? `${('Entree directe à la ')} ${elem.rangactuel}${"e"} ${('place')}`  : `+${elem.evorang}`}
                </Typography>
              </Box> */}
        </Grid>
      )
    }
    private drawHitElem0(elem:any, entree:boolean) {
      return (
        <Card
            style={{
                margin: "10px",
                border: 'none',
                borderColor: 'none',
                backgroundColor: this.props.theme.Club40.background.elem
            }}
        >
            <Box sx={{ p: 2.25 }}>
                <MD.Grid container direction="row" style={{justifyContent:"center",
                alignItems:"center"}} >
                <MD.Grid item xs={2}>
                    <CardContent>
                        <Typography component="div">
                        {entree ? this.props.i18n._('Meileure entrée du top' ) : this.props.i18n._('Meileure progression du top' )}
                        </Typography>
                    </CardContent>
                </MD.Grid>
                <MD.Grid item xs={4} style={{textAlign: 'center',
                display: 'flex',
                justifyContent: 'center'}}>
                    <CardMedia
                        component="img"
                        style={{ width: 60, height: 60 }}
                        image={changePochetteFilepath(elem.referencetitre)}
                        alt="Live from space album cover"
                    />
                </MD.Grid>
                <MD.Grid item xs={6}>
                    <CardContent>
                        <Typography component="div">
                            {elem.nominterprete}
                        </Typography>
                        <Typography component="div">
                            {elem.titremusical}
                        </Typography>
                        <Typography component="div">
                            {elem.nomdistributeur}/{elem.nomlabel}
                        </Typography>
                        <Typography component="div">
                            {entree ? `${this.props.i18n._('Entree directe à la ')} ${elem.rangactuel}${"e"} ${this.props.i18n._('place')}`  : `+${elem.evorang}`}
                        </Typography>
                    </CardContent>
                </MD.Grid>
                </MD.Grid>
            </Box>
        </Card>
      )
    }

    private drawHitsContent() {
        return (
            <MD.Paper style={{marginTop: 30, borderRadius: 8}}>
                <Grid container spacing={3} direction={'column'}>
                    <StyledGrid item xs style={{marginLeft: "10px", paddingBottom: 0, height: "36px", minHeight: "36px"}}>
                        <Typography  style={{fontWeight: "bold", fontSize: 16, color: 'white'}}>{this.props.i18n._('Les hits à suivre')}...</Typography>
                    </StyledGrid>
                    <Grid item xs>
                    <Grid container spacing={3}>
                        {(this.props.classementClub.upcoming_hits.ENTREE[0]) !== undefined && this.drawHitElem(this.props.classementClub.upcoming_hits.ENTREE[0], true)}
                        {(this.props.classementClub.upcoming_hits.DIFFUSION[0]) !== undefined && this.drawHitElem(this.props.classementClub.upcoming_hits.DIFFUSION[0], false)}
                    </Grid>
                    </Grid>
                </Grid>
            </MD.Paper>
        )
    }

    public render(): React.ReactNode {
        const { classes, i18n } = this.props;
        return(
        <>
          {
          this.props.classementClub.get_hits_loading ? 
            <MD.Grid
            style={{margin: '16px',width: '100%',
            position:'relative'}}>
                <MD.CircularProgress color='secondary'/>
            </MD.Grid> 
          : 
            this.drawHitsContent() 
          }
        </>
        )
    }
}

const mapStateToProps = ({ i18n, classementClub, layout }: IApplicationState) => ({
    i18n: i18n.i18n,
    classementClub,
    layout
});


const mapDispatchToProps = (dispatch: Dispatch) => ({
});

export const UpcomingHits =
  connect(mapStateToProps, mapDispatchToProps)(MD.withTheme(MD.withStyles(styles)(UpcomingHitsComponent)));