import * as MD from '@material-ui/core';
import * as React from 'react';
import { $CombinedState, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  DatePicker
} from '@material-ui/pickers';
import { IApplicationState, IConnectedReduxProps } from '../../Store';

import { ILayoutState } from '../../Store/Layout/Types';

import { I18N, ILocaleInfos } from '../../Store/I18n/Types';
import { ButtonDensed } from '../../Themes/StyledElements';

import { RouterState } from 'connected-react-router';
import moment from 'moment';
import { getDate2WeeksBeforeTodayHourZero, getDateSevenDaysBeforeTodayHourZero, getNextWeekDay, toLocaleDateString } from '../../Utils/Time';
import { PaletteMode } from '../../Themes';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../Utils/Various';
import { getClassementClub, getDateCloture, getHits, sendEmailNewsLetter } from '../../Store/ClassementClub/Actions';
import { generateFileXML } from '../../Store/GenerateFile/Actions';

import ClearIcon from '@material-ui/icons/Clear';
import { ClassementDatesSelect } from './ClassementDatesSelect';
import { Box, Button, Typography } from '@material-ui/core';
import { IClassementClubState } from '../../Store/ClassementClub/Types';

interface IPropsFromState {
    i18n: I18N;
    layout: ILayoutState;
    localeInfos: ILocaleInfos;
    router: RouterState;
    classementClub: IClassementClubState
}

interface IState {
  selected_date: Date;
  date_range: Date[];
  week_number: number;
  email: string;
  isValidEmail: boolean | null;
  date_cloture: Date | null;
}

interface IPropsTheme {
  theme: any;
}

interface IPropsFromDispatch {
  getClassementClub: typeof getClassementClub;
  generateFileXML: typeof generateFileXML;
  sendEmailNewsLetter: typeof sendEmailNewsLetter;
  getHits: typeof getHits;
  getDateCloture: typeof getDateCloture;
}

type AllProps =
    MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IPropsTheme &
    IConnectedReduxProps;

const styles = (theme: any) => MD.createStyles({
    drawerPaper: {
        width: theme.Club40.dimensions.drawerWidth,
        paddingTop: '14px',
        paddingLeft: '15px',
        paddingRight: '15px',
        backgroundColor: theme.palette.background.default,
        //position: 'absolute'
    },
    launchSearch: {
      marginTop: '20px',
      color: 'black'

    },
    infos:{
      fontSize: '16px',
      fontWeight: 600,
      backgroundColor: theme.palette.background.paper,
      //display: 'block',
      padding: '10px 10px 5px',
      marginBottom: '10px',
      //position: 'fixed',
      //top: 40,
      left: 0,
      width: '100%',
      //zIndex: 1201
  },
  drawer: {
    '&.hidden': {
        width: '0px',
    },
    flexShrink: 0,
},
form: {
  marginTop:'-20px',
  display: 'flex',
  flexFlow: 'row',
  flexWrap: 'wrap',
  paddingTop: 35,
  placeContent: 'center'
},
marginEmail: {
  width: '100%',
  marginTop: "20px",
  marginBottom:"-1px"
},
clearIcon:{
  fontSize:'smaller',
  marginRight:'-10px'
},
iconButton:{
  "&:hover": {
      backgroundColor: "transparent"
    },
  color:theme.palette.primary.main
},
inputLabel:{
  marginTop:'-2px',
  fontFamily: 'Lato',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  letterSpacing: '0px',
  textAlign: 'left',
  color: theme.palette.text.primary
},
});


const DatePickerStyled = MD.withStyles({
  root: {
      fontSize: '12px'
  }
})(DatePicker);

export class DrawerComponent extends React.Component<AllProps, IState> {

    public constructor(props: AllProps) {
      super(props);
      this.state = {
        selected_date: new Date(),
        date_range: [],
        week_number: 1,
        email: '',
        isValidEmail: null,
        date_cloture: null
      }
    }

    static getDerivedStateFromProps(props: AllProps, state: IState) {
      if (state.date_cloture === null && props.classementClub.date_cloture !== null) {
        state.date_cloture = props.classementClub.date_cloture;
        let dateString  = state.date_cloture;
        //let minDate = new Date("2011-12-09");//Fri Dec 09 2011 01:00:00 GMT+0100 (heure normale d’Europe centrale)
        let minDateObject = moment("09/12/2011", "DD/MM/YYYY"); 
        let minDate = minDateObject.toDate();
        console.log("===minDate ", minDate)
        //let today = Date(state.date_cloture);//getDateSevenDaysBeforeTodayHourZero(new Date());
        let dateMomentObject = moment(dateString, "DD/MM/YYYY"); 
        var date_cloture = dateMomentObject.toDate();
        console.log("===date_cloture ", date_cloture)
        let dates:Date[] = [];
        
        let date = minDate;
        while (date <= date_cloture) {
          dates.push(date);
          date = getNextWeekDay(date);
        }

        dates = dates.reverse();
        state.date_range = dates;
        state.selected_date = dates[0];
        state.week_number = moment(dates[0], "MM-DD-YYYY").add(6, 'days').week()
        props.getClassementClub(dates[0]);
      }
      return state;
    }

    public componentDidMount() {
      this.props.getDateCloture()
      
      //this.props.getHits(dates[0])
      
    }

    private drawSearchButton() {
      const { classes } = this.props;

      return (
        <ButtonDensed
            style={{marginTop: '30px'}}
            className={classes.launchSearch}
            color='secondary'
            variant='contained'
            onClick={()=>{}}
        >
          {this.props.i18n._('Launch search')}
        </ButtonDensed>
      )
    }
    private onClickFile(type:string) {
      this.props.generateFileXML(this.props.classementClub.selected_date)
    }
    private drawXMLButton() {
      const { classes } = this.props;

      return (
      <MD.Grid container direction="column" style={{width: '80%',backgroundColor: '#313030', marginTop: 30, padding:10, alignContent: 'center', alignItems: 'center'}}>
                <MD.Grid item xs>
                  <MD.Typography>
                    Publier le classement sur votre site
                  </MD.Typography>
                </MD.Grid>
                <MD.Grid item xs>
                  <ButtonDensed
                      style={{marginTop: '30px', fontWeight: 600}}
                      className={classes.launchSearch}
                      variant='contained'
                      onClick={()=>{this.onClickFile('xml')}}
                  >
                    {this.props.i18n._('XML')}
                  </ButtonDensed>
                </MD.Grid>
               
              </MD.Grid>    
      )
    }

    private onEmailChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
      this.setState({
        email: e.currentTarget.value
      })   
    }

    private clear(name:string){
      switch(name){
          case 'email':
              this.setState({email: ''})
              break;
      }
    }

    private echeck(str: string) {
      const at = "@";
      const dot = ".";
      const lat = str.indexOf(at);
      const lstr = str.length;
      const ldot = str.indexOf(dot);
      
      if (str.indexOf(at) === -1) return false;
      if (str.indexOf(at) === -1 || str.indexOf(at) === 0 || str.indexOf(at) === lstr) return false;
      if (str.indexOf(dot) === -1 || str.indexOf(dot) === 0 || str.indexOf(dot) === lstr) return false;
      if (str.indexOf(at, lat + 1) !== -1) return false;
      if (str.substring(lat - 1, lat) === dot || str.substring(lat + 1, lat + 2) === dot) return false;
      if (str.indexOf(dot, lat + 2) === -1) return false;
      if (str.indexOf(" ") !== -1) return false;
      return true;
    };
    private submitEmail() {
      if (this.state.isValidEmail) {
        this.props.sendEmailNewsLetter(this.state.email)
      } 
      return null;
    }

    private validateEmail(email:string) {
      const isValid = this.echeck(email)
      console.log("onblur")
      this.setState({
        isValidEmail: isValid
      })
    }

    private drawEmailNewsletter() {
      const { classes, i18n} = this.props;

      return (
        <MD.Grid container direction="column" style={{width: '80%',backgroundColor: '#313030', marginTop: 30, padding:10, alignContent: 'center', alignItems: 'center'}}>
          <MD.Grid item xs>
            <MD.Typography>
              Recevoir la newletter hebdomadaire
            </MD.Typography>
          </MD.Grid>
          <MD.Grid item xs>
            <MD.TextField
              className={classes.marginEmail}
              label={i18n._('ton adresse email')}
              variant="outlined"
              error={this.state.isValidEmail === false && this.state.email !== ''}
              helperText={this.state.email !== '' ? this.state.isValidEmail === false ? "Invalid email format" : "" : ''}
              id="mui-theme-provider-outlined-input"
              value={this.state.email}
              name ="email"
              onBlur={() => this.validateEmail(this.state.email)}
              onChange={(e) => this.onEmailChange(e)}
              onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                      //this.onSearch();
                  }
              }}
              InputProps={{
                  endAdornment:(
                  <MD.InputAdornment position="end" >
                    <MD.IconButton 
                      className={classes.iconButton}
                      aria-label="clear"
                      onClick={this.clear.bind(this,'email')}
                      edge="end"
                    >
                      {this.state.email !== ''?
                      <React.Fragment>
                          <MD.Divider orientation="vertical" flexItem />
                          <ClearIcon className={classes.clearIcon}/>
                      </React.Fragment>
                      : null }
                    </MD.IconButton>
                  </MD.InputAdornment>
                  )
              }}
              InputLabelProps={{
                  className: classes.inputLabel
              }}
            />
          </MD.Grid>
          <MD.Grid item xs>
            <ButtonDensed
                style={{marginTop: '30px', fontWeight: 600}}
                className={classes.launchSearch}
                variant='contained'
                onClick={()=>{ this.submitEmail()}}
            >
              {this.props.i18n._('Valider')}
            </ButtonDensed>
          </MD.Grid>
          
        </MD.Grid>  
      )
    }

    private onDateChange(e:any) {
      this.setState({
          selected_date: e.target.value,
          week_number: moment(e.target.value, "MM-DD-YYYY").add(6, 'days').week()
      })
      this.props.getClassementClub(e.target.value);
      //this.props.getHits(e.target.value);
    }

    private drawDatesRange() {
      return (
        <MD.Grid container direction='column' style={{width: '80%',backgroundColor: '#313030', padding:10, alignContent: 'center', alignItems: 'center'}} >
          <MD.Grid item style={{paddingBottom: "20px"}}>
            <MD.Typography>
              {this.props.i18n._('Afficher le classement du week end')}
            </MD.Typography>
          </MD.Grid>
          <MD.Grid item>
            <ClassementDatesSelect {...this.props} />
          </MD.Grid>
        </MD.Grid>
      )
    }

    private drawWeekInfos() {
      const { classes, theme } = this.props;
      return (
        <div id="infos" className={classes.infos}
        style={{color: theme.palette.type === PaletteMode.DARK ? theme.palette.primary.main : theme.palette.secondary.main}}
        >
          <div style={{display: 'inline-flex'}}>
          <p style={{margin: 0, color: "white"}}>
          {this.props.i18n._('General classification')}&nbsp;
          </p>
          <p style={{margin: 0, color: this.props.theme.palette.text.primary}}>
          &nbsp;{` ${ " [ " } `}&nbsp;
          </p>
          <p style={{margin: 0, color: "white"}}>
          WEEK END &nbsp; {this.state.week_number}&nbsp;
          </p>
          <p style={{margin: 0, color: this.props.theme.palette.text.primary}}>
          {` ${ " ] " } `}&nbsp;
          </p>
          <p style={{margin: 0, color: this.props.theme.palette.text.primary}}>
              {this.props.i18n._('from').toLowerCase()}&nbsp;
              {toLocaleDateString(this.state.selected_date)}&nbsp;
              {this.props.i18n._('to').toLowerCase()}&nbsp;
              {toLocaleDateString(moment(this.state.selected_date).add(1,'d').toDate())}&nbsp;
          </p>
          </div>
          
        </div>
      )
    }

    private drawContactDjClub() {
      return (
        <Box p={2} style={{marginTop: "20px"}}>
          <Typography variant="h5" component="h5" gutterBottom style={{textAlignLast: 'center', marginBottom: "10px"}}>
            Vous etes DJ en club ?
          </Typography>
          <Typography style={{textAlignLast: 'center', marginBottom: "10px", fontSize: 16}}>
            Participer au classement
          </Typography>
          <Box
            //variant="contained"
            color="warning"
            style={{backgroundColor: '#f50057', borderRadius: 8, padding: 4, textAlign: 'center', color: 'white'}}
            //size="small"
            //fullWidth
          >
            <div style={{display: 'block'}}>
              <Typography>
                Rejoignez le panel !
              </Typography>
             
            <br/>
            <Typography variant="h5" component="h5" gutterBottom style={{textTransform:'uppercase', fontSize: 15}}>
            club40@yacast.fr
            </Typography>
            </div>
          </Box>
        </Box>
      )
    }
    private drawLiensPartenaires() {

      return (
        <Box p={2} style={{textAlignLast: 'center'}}>
        <Button
            style={{fontWeight: 600}}
            href="/lienspartenaires"
            size="large"
            variant="contained"
            target="_blank"
          >
            Liens partenaires
          </Button>
        </Box>
      )
    }

    public render(): React.ReactNode {
        const { classes, layout } = this.props;
        return (
          <MD.Grid container direction="row">
            {/* <MD.Grid item>
              {this.drawWeekInfos()}
            </MD.Grid>  */}
            <MD.Grid item>
              <MD.Drawer
               anchor='left'
               classes={{ paper: classes.drawerPaper }}
               className={classes.drawer + ((layout.drawerContent && layout.showDrawer) ? '' :'hidden')}
               open={layout.showDrawer}
               variant='persistent'
              >
                <div className={classes.form} >

                { this.drawDatesRange() }
                { this.drawXMLButton() }
                { this.drawEmailNewsletter() }
                { this.drawContactDjClub() }
                {this.drawLiensPartenaires()}
                </div>
                
              </MD.Drawer>
            </MD.Grid>
          
          </MD.Grid>
          
        );
    }
}

const mapStateToProps = ({ i18n, layout, router, classementClub }: IApplicationState) => ({
  i18n: i18n.i18n,
  localeInfos: i18n.localeInfos,
  layout,
  router,
  classementClub
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getClassementClub: (date_current_cloture: Date) => dispatch(getClassementClub(date_current_cloture)),
  generateFileXML: (date_current_cloture: Date) => dispatch(generateFileXML(date_current_cloture)),
  sendEmailNewsLetter: (email:string) => dispatch(sendEmailNewsLetter(email)),
  getHits: (date_current_cloture: Date) => dispatch(getHits(date_current_cloture)),
  getDateCloture: () => dispatch(getDateCloture()),
});

export const Drawer =
  connect(mapStateToProps, mapDispatchToProps)(MD.withTheme(MD.withStyles(styles)(DrawerComponent)));
