import { Reducer } from 'redux';

import { INotificationBarState, NotificationBarActionTypes } from './Types';

export const INotificationBarInitialState : INotificationBarState = {
  autherror: null,
  error: null,
  infoMessage: null,
  successMessage: null,
}

const reducer: Reducer<INotificationBarState> = (state = INotificationBarInitialState, action) => {
    switch (action.type) {
        case NotificationBarActionTypes.SET_ERROR: {
          return {
            ...state,
            error: action.payload
          };
        }

        case NotificationBarActionTypes.SET_AUTH_ERROR: {
          return {
            ...state,
            autherror: action.payload
          };
        }

        case NotificationBarActionTypes.SET_SUCCESS_MESSAGE: {
          return {
            ...state,
            successMessage: action.payload
          };
        }

        case NotificationBarActionTypes.SET_INFO_MESSAGE: {
          return {
            ...state,
            infoMessage: action.payload
          };
        }
        default: {
          return state;
        }
    }
};

export { reducer as NotificationBarReducer };
