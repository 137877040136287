export enum IClassementClubActionTypes {
    GET_CLASSEMENT_CLUB = '@@classementclub/GET_CLASSEMENT_CLUB',
    GET_CLASSEMENT_CLUB_SUCCESS = '@@classementclub/GET_CLASSEMENT_CLUB_SUCCESS',
    GET_CLASSEMENT_CLUB_ERROR = '@@classementclub/GET_CLASSEMENT_CLUB_ERROR',

    SEND_EMAIL_NEWSLETTER = '@@classementclub/SEND_EMAIL_NEWSLETTER',
    SEND_EMAIL_NEWSLETTER_SUCCESS = '@@classementclub/SEND_EMAIL_NEWSLETTER_SUCCESS',
    SEND_EMAIL_NEWSLETTER_ERROR = '@@classementclub/SEND_EMAIL_NEWSLETTER_ERROR',

    GET_HITS = '@@classementclub/GET_HITS',
    GET_HITS_SUCCESS = '@@classementclub/GET_HITS_SUCCESS',
    GET_HITS_ERROR = '@@classementclub/GET_HITS_ERROR',

    GET_CLUBS = '@@classementclub/GET_CLUBS',
    GET_CLUBS_SUCCESS = '@@classementclub/GET_CLUBS_SUCCESS',
    GET_CLUBS_ERROR = '@@classementclub/GET_CLUBS_ERROR',

    GET_DATE_CLOTURE = '@@classementclub/GET_DATE_CLOTURE',
    GET_DATE_CLOTURE_SUCCESS = '@@classementclub/GET_DATE_CLOTURE_SUCCESS',
    GET_DATE_CLOTURE_ERROR = '@@classementclub/GET_DATE_CLOTURE_ERROR',

    SAVE_SELECTED_DATE_WEEK_NUMBER = '@@classementclub/SAVE_SELECTED_DATE_WEEK_NUMBER',

}

export interface IClassementClub {
    "distributeur" :  string,
    "id" :  number,
    "interprete" :  string,
    "label" :  string,
    "pochette_filepath" : string,
    "rang" :  number,
    "rang_precedent" :  number,
    "titre" :  string,
    "bpm" :  number | null,
    "master_filepath": string
}
export interface IDiffusion {
   "evorang": number,
   "nomdistributeur": string,
   "nominterprete": string,
   "nomlabel": string,
   "referencetitre": string,
   "titremusical": string
}
export interface IEntrance {
    "nomdistributeur": string,
    "nominterprete": string,
    "nomlabel": string,
    "rangactuel": number,
    "referencetitre": string,
    "titremusical": string
}
export interface IUpcomingHits {
    DIFFUSION : IDiffusion[],
    ENTREE: IEntrance[]
}
export interface IClassementClubState {
    readonly get_classmentclub_loading: boolean;
    readonly classement_club_results: IClassementClub[];
    readonly get_hits_loading: boolean;
    readonly upcoming_hits: IUpcomingHits;
    readonly get_clubs_loading: boolean;
    readonly clubs_members: any;
    readonly week_number: number
    readonly selected_date: Date
    readonly date_cloture: Date | null
}
