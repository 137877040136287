import { connectRouter, LocationChangeAction, RouterState } from 'connected-react-router';
import { History } from 'history';
import { Action, AnyAction, combineReducers, Dispatch } from 'redux';
import * as effects from 'redux-saga/effects';
import { Reducer } from 'typesafe-actions';

import { LayoutReducer } from './Layout/Reducer';
import { LayoutSaga } from './Layout/Sagas';
import { ILayoutState } from './Layout/Types';

import { UserReducer } from './User/Reducer';
import { UserSaga } from './User/Sagas';
import { IUserState } from './User/Types';

import { I18NReducer } from './I18n/Reducer';
import { I18NSaga } from './I18n/Sagas';
import { I18NState } from './I18n/Types';

import { NotificationBarReducer } from './NotificationBar/Reducer';
import { INotificationBarState } from './NotificationBar/Types';

import { IClassementClubState } from './ClassementClub/Types';
import { ClassementClubReducer } from './ClassementClub/Reducer';
import { ClassementClubSaga } from './ClassementClub/Sagas';
import { IGenerateFileState } from './GenerateFile/Types';
import { GenerateFileReducer } from './GenerateFile/Reducer';
import { GenerateFileSaga } from './GenerateFile/Sagas';

export interface IApplicationState {
  i18n: I18NState;
  layout: ILayoutState;
  notificationBar: INotificationBarState;
  router: RouterState;
  user: IUserState;
  classementClub: IClassementClubState;
  generatefile: IGenerateFileState;
}

export interface IConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

export const RootReducer = (history: History) => combineReducers<IApplicationState>({
  i18n: I18NReducer,
  layout: LayoutReducer,
  notificationBar: NotificationBarReducer,
  router: connectRouter(history) as Reducer<RouterState, LocationChangeAction>,
  user: UserReducer,
  classementClub: ClassementClubReducer,
  generatefile: GenerateFileReducer
});

export function* RootSaga() {
    yield effects.all([
      effects.fork(I18NSaga),
      effects.fork(LayoutSaga),
      effects.fork(UserSaga),
      effects.fork(ClassementClubSaga),
      effects.fork(GenerateFileSaga)
    ]);
}
