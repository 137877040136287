import * as MD from '@material-ui/core';
import * as React from 'react';
import { DashboardContent } from '../Components/Dashboard/Content';
import { Layout } from '../Components/Layout';
import { setDrawerContent, setPageContent, showDrawer } from '../Store/Layout/Actions';
import { Route, RouteComponentProps } from 'react-router-dom';
import { IApplicationState, IConnectedReduxProps } from '../Store';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';


interface IPropsFromDispatch {
    setDrawerContent: typeof setDrawerContent;
    setPageContent: typeof setPageContent;
    showDrawer: typeof showDrawer;
}

type AllProps =
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class HomePage extends React.Component<AllProps> {

    public render(): React.ReactNode {
      this.props.showDrawer();
      this.props.setPageContent(<Route component={DashboardContent} />);
      return (
          <Route component={Layout} />
      );
    }
}


const mapDispatchToProps = (dispatch: Dispatch) => ({
    setDrawerContent: (c: React.ReactNode) => dispatch(setDrawerContent(c)),
    setPageContent: (c: React.ReactNode) => dispatch(setPageContent(c)),
    showDrawer: () => dispatch(showDrawer()),
});

export const Home = connect(null, mapDispatchToProps)(HomePage);
