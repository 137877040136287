import moment from 'moment';
import { Reducer } from 'redux';
import { getDate2WeeksBeforeTodayHourZero, getDateSevenDaysBeforeTodayHourZero, getNextWeekDay } from '../../Utils/Time';
import { IClassementClub, IClassementClubActionTypes, IClassementClubState } from './Types';

const getSelectedDateWeekNumber = () => {
    let minDate = new Date("2011-12-09");//Fri Dec 09 2011 01:00:00 GMT+0100 (heure normale d’Europe centrale)
    let today = getDateSevenDaysBeforeTodayHourZero(new Date());
    let dates:Date[] = [];
    
    let date = minDate;
    while (date < today) {
        dates.push(date);
        date = getNextWeekDay(date);
    }

    dates = dates.reverse();
    let selected_date:Date = dates[0];
    let week_number:number =  moment(dates[0], "MM-DD-YYYY").add(6, 'days').week()
    return { selected_date, week_number }
}
let { selected_date, week_number } = getSelectedDateWeekNumber()

let initialState: IClassementClubState = {
    classement_club_results: [],
    get_classmentclub_loading: false,
    get_hits_loading: false,
    upcoming_hits: {
        DIFFUSION: [],
        ENTREE: []
    },
    clubs_members: '',
    get_clubs_loading: false,
    week_number: week_number,
    selected_date: selected_date,
    date_cloture: null
}

export function changePochetteFilepath(path: string) {
    let new_path = path.split('\\').slice(3).join('/').replace('metadata', 'https://pige.yacast.fr/meta');
    return new_path
  }

export function changeMasterFilepath(path: string) {
    let new_path = path.split('\\').slice(2).join('/').replace('storage.yfr-isilon.yacast.fr', 'mnt/isilon/yf/metadata');
    return new_path
  }

const reducer: Reducer<IClassementClubState> = (state = initialState, action) => {
    switch (action.type) {
        case IClassementClubActionTypes.GET_CLASSEMENT_CLUB: {
            return {
                ...state,
                get_classmentclub_loading: true,
                classement_club_results: []
            }
        }
        case IClassementClubActionTypes.GET_CLASSEMENT_CLUB_SUCCESS: {
            let results = action.payload;
            results = results.map((x:IClassementClub) => {
                return {
                    ...x,
                    pochette_filepath: changePochetteFilepath(x.pochette_filepath),
                    master_filepath: changeMasterFilepath(x.master_filepath)
                }
            })

            return {
                ...state,
                get_classmentclub_loading: false,
                classement_club_results: results
            }
        }
        case IClassementClubActionTypes.GET_CLASSEMENT_CLUB_ERROR: {
            return {
                ...state,
                get_classmentclub_loading: false,
                classement_club_results: []
            }
        }
        case IClassementClubActionTypes.GET_HITS: {
            return {
                ...state,
                get_hits_loading: true,
                upcoming_hits: {
                    DIFFUSION: [],
                    ENTREE: []
                }
            }
        }
        case IClassementClubActionTypes.GET_HITS_SUCCESS: {
            return {
                ...state,
                get_hits_loading: false,
                upcoming_hits: action.payload
            }
        }
        case IClassementClubActionTypes.GET_HITS_ERROR:{
            return {
                ...state,
                get_hits_loading: false,
                upcoming_hits: {
                    DIFFUSION: [],
                    ENTREE: []
                }
            }
        }
        case IClassementClubActionTypes.GET_CLUBS: {
            return {
                ...state,
                get_clubs_loading: true,
                clubs_members: ''
            }
        }
        case IClassementClubActionTypes.GET_CLUBS_SUCCESS: {
            return {
                ...state,
                get_clubs_loading: false,
                clubs_members: action.payload
            }
        }
        case IClassementClubActionTypes.GET_CLUBS_ERROR: {
            return {
                ...state,
                get_clubs_loading: false,
                clubs_members: ''
            }
        }
        case IClassementClubActionTypes.SAVE_SELECTED_DATE_WEEK_NUMBER: {
            return {
                ...state,
                selected_date: action.payload[0],
                week_number: action.payload[1]
            }
        }
        case IClassementClubActionTypes.GET_DATE_CLOTURE: {
            return {
                ...state,
            }
        }
        case IClassementClubActionTypes.GET_DATE_CLOTURE_SUCCESS: {
            console.log("===action.payload ",action.payload)
            return {
                ...state,
                date_cloture: action.payload
            }
        }
        case IClassementClubActionTypes.GET_DATE_CLOTURE_ERROR: {
            return {
                ...state
            }
        }
        default: {
            return state;
        }
    }
};

export { reducer as ClassementClubReducer };
