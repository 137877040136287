import moment from 'moment';
import * as effects from 'redux-saga/effects';

import { Backend } from '../../Backend/Backend';
import { Config } from '../../config';
import { Logger } from '../../Utils/Logger';
import { fromUTC, getDateHourZero } from '../../Utils/Time';
import { generateFileXML, generateFileXMLSuccess } from './Actions';
import { IGenerateFileActionTypes } from './Types';


function openxml(xml_str: string)
    {
        let url = `${Config.backendAPIURL}/export`
        let myWindow = window.open(url,"_blank");
        
        if (myWindow) {
            myWindow.document.open('content-type: text/xml');
            myWindow.document.write(xml_str);
            //myWindow.document.close();
        }
    }

    function* handleGetGenerateFileXML(action: ReturnType<typeof generateFileXML>) {
        const backend = Backend.getInstance();
        try {
            //const res = yield effects.apply(backend, backend.generateFile, [action.payload, 'xml'] as any);
            Logger.info('Successfully getGenerateFile');
            //openxml(res)
            let date = moment(fromUTC(getDateHourZero(new Date(action.payload)))).format("DD/MM/YYYY HH:mm:ss")
            let xhr = new XMLHttpRequest();
            let url = `${Config.backendAPIURL}/export_file?type=xml&date_current_cloture=${date}`
            xhr.open('GET', url)
            xhr.setRequestHeader("Content-Type", "text/xml");
            xhr.send();
            xhr.onload = (data:any) => {
                // Request finished. Do processing here.
                console.log("youpiii ",data)
                window.open(url, '_blank');
                };
            /* if (res.status_code === 200) {
                yield effects.put(generateFileXMLSuccess(res.results))
                let blob = new Blob([res.results], {type: 'text/xml'});
                let url = URL.createObjectURL(blob);
                
                window.open(url, '_blank');
                URL.revokeObjectURL(url); //Releases the resources
            } */
            
        } catch (err) {
            Logger.warn({ err }, 'Failed getGenerateFile');
        }
    }


function* watchGenerateFile() {
    yield effects.takeEvery(IGenerateFileActionTypes.GENERATE_FILE_XML, handleGetGenerateFileXML);
}

export function* GenerateFileSaga() {
    yield effects.all([
        effects.fork(watchGenerateFile),
    ]);
}
