import * as MD from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import MuiButton from '@material-ui/core/Button';
import MuiTextField from '@material-ui/core/TextField';
import { TextField } from '@material-ui/core';
import MuiRadio from '@material-ui/core/Radio';
import MuiCheckbox from '@material-ui/core/Checkbox';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiTableCell from '@material-ui/core/TableCell';

import Paper, { PaperProps } from '@material-ui/core/Paper';
import Draggable  from 'react-draggable';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

//Router
import {
  Link
} from "react-router-dom";
import { I18N } from '../Store/I18n/Types';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
const styles = (theme:any) => MD.createStyles({
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '5px',
    color: theme.palette.grey[500],
  },
})

export const ButtonDensed = MD.withStyles({
    root: {
        padding: '1px 8px',
        fontSize: '12px',
        color: '#fff',
        textTransform: 'none'
    }
})(MuiButton);

export const ButtonDensedNoColor = MD.withStyles({
  root: {
      padding: '1px 8px',
      fontSize: '12px',
  }
})(MuiButton);

export const DensedTextField = styled(TextField)`
.MuiInputBase-input {
  padding: 4px;
  font-size: 13px;
}
.MuiInputLabel-formControl {
  top: -7px;
  font-size: 13px;
  font-weight: 300;
}
.MuiFormLabel-root.Mui-focused {
  top: 0;
}
.MuiInputLabel-shrink {
  top: 0 !important;
}
`;

export const DensedTextFieldDark = styled(TextField)`
.MuiInputBase-input {
  padding: 4px;
  font-size: 13px;
}
.MuiInputLabel-formControl {
  top: -7px;
  font-size: 13px;
  font-weight: 100;
}
.MuiFormLabel-root.Mui-focused {
  top: 0;
}
.MuiInputLabel-shrink {
  top: 0 !important;
}
input {
  color: #000
}
`;


export const DarkRadio = withStyles({
    root: {
        color: grey[400],
        '&$checked': {
            color: grey[600],
        },
        
    },
  
    checked: {},
  })(MuiRadio);

export const DarkCheckbox = withStyles({
    root: {
        color: grey[300],
    },
  
    checked: {},
})(MuiCheckbox);

export const FormControlLabelMedium = withStyles({
  label: {
    fontSize: '14px'
  }
})(MuiFormControlLabel);

export const FormControlLabelSmall = withStyles({
    label: {
      fontSize: '13px'
    }
})(MuiFormControlLabel);

export const FormControlLabelTopLeftPlacement = withStyles({
  label: {
    fontSize: '14px',
    display: 'flex',
    alignSelf: 'flex-start'
  }
})(MuiFormControlLabel);

export const StyledLink = styled(Link)`
    text-decoration: none;
    color: #fff;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;
export const CustomTableHeaderCell = withStyles(theme => ({ 
  root: {
    padding: '0px 5px',
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: 500,
    color: theme.palette.text.primary
  }
}))(MuiTableCell);
export const TableHeaderCell = withStyles(theme => ({ 
  root: {
    padding: '0px 5px',
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: 500,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary
  }
}))(MuiTableCell);

export const TableCell = MD.withStyles((theme:any) => ({
  root: {
    color: theme.palette.text.primary,
    opacity: '100%',
    borderBottom: '0px solid',
    fontFamily: 'Lato',
    fontWeight: 400,
    borderRadius: 8
  }
}))(MD.TableCell);

export const CustomTooltip = withStyles((theme: any) => ({
  tooltip: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    fontSize:'11px',
    opacity: "0.93 !important",
  }
}))(MD.Tooltip);

export interface DialogTitleProps extends MD.WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  i18n: I18N;
  layout: any;
  theme: any;
}
export const DialogTitle = MD.withTheme(MD.withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, i18n, layout, theme, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other} style={{cursor:'move', color: theme.palette.primary.main, padding: theme.spacing(2)}}>
      <MD.Typography >{children}</MD.Typography>
      {onClose ? (
        <CustomTooltip title= {i18n._("Close")}>
          <MD.IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseRoundedIcon />
          </MD.IconButton>
        </CustomTooltip>
      ) : null}
    </MuiDialogTitle>
  );
}))
export function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} style={{overflow:'hidden'}}/>
    </Draggable>
  );
}
