import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { IApplicationState, IConnectedReduxProps } from '../Store';
import { ILayoutState } from '../Store/Layout/Types';

import { getTheme } from '../Themes';

import { AppBar } from './AppBar';
import { NotificationBar } from './NotificationBarComponent';
import { Drawer } from './Sidebar/Drawer';


interface IPropsFromState {
    layout: ILayoutState;
    ui: any;
    updateUI: (name: string, value: any) => void;
}

type AllProps =
    MD.WithStyles<typeof styles> &
    IPropsFromState &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

const theme = getTheme();

const styles = (theme: any) => MD.createStyles({
    container: {
        height: '100%',
    },
    content: {
        '&.noDrawer': {
            paddingLeft: theme.spacing(3),
        },
        flexGrow: 1,
        minHeight: `calc(100% - ${theme.Club40.dimensions.appBarHeight}px)`,
        //overflow: 'hidden',
        padding: theme.spacing(3),
        paddingLeft: theme.Club40.dimensions.drawerWidth + theme.spacing(3),
        paddingTop: theme.Club40.dimensions.appBarHeight,
        transition: 'padding-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    },
    toolbar: theme.mixins.toolbar,
    listGridContainer: {
      margin: '15px'
    },
    listItemContainer: {
      border: '1px solid #cccccc',
      padding: '5px',
      borderRadius: '5px',
    }
});

interface IOwnState {
    isDisconnected: boolean;
  }
  

export class LayoutComponent extends React.Component<AllProps, IOwnState> {
    public constructor(props: AllProps) {
        super(props);
        this.state = {
            isDisconnected: false,
        };
      }
  
      public componentDidMount() {
        //document.body.style.overflow = 'hidden';
        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
      }
  
      componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
      }

      
    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'online') {
          if (this.state.isDisconnected)  {
            return this.setState({ isDisconnected: false });
          }
          return;
        }
        if (!this.state.isDisconnected)  {
          return this.setState({ isDisconnected: true });
        }
    }
  
      private showNoticeIfDisconnected = () => {
        if (this.state.isDisconnected) {
          return (
            <div style={{ background: "red", zIndex: 10000, position: "absolute", width: "100%", textAlign: "center", opacity: "0.5"}}>
                Pas de réseaux
            </div> )
        }
      }
    public render(): React.ReactNode {
        const { classes, layout } = this.props;
        console.log("this.props.layout.showDrawer", this.props.layout.showDrawer)
        return (
                <MD.Grid className={classes.container} id='fffff' container>
                    {this.showNoticeIfDisconnected()}
                    {/* <Route component={AppBar} /> */}
                    <Route component={Drawer} />
                    <MD.Grid
                        item
                        className={classes.content + (
                            (this.props.layout.showDrawer) ?
                            '' :
                            ' noDrawer'
                        )}
                        xs={12}
                    >
                        {this.props.layout.pageContent}
                        <NotificationBar/>
                    </MD.Grid>
                </MD.Grid>
        );
    }
}

const mapStateToProps = ({ layout }: IApplicationState) => ({
    layout,
});

export const Layout =
    connect(mapStateToProps)(
        MD.withStyles(styles)(LayoutComponent),
    );
