export function capitalize(str: string) {
    return str
        .toLocaleLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toLocaleUpperCase() + s.substring(1))
        .join(' ');
}

export function initials(str: string, uppercase: boolean = true) {
    return slugify(str, uppercase)
        .split('-')
        .map((s) => s.charAt(0))
        .join('.') + '.';
}

export function slugify(str: string, uppercase: boolean = true) {
    str = (uppercase ? str.toLocaleUpperCase() : str.toLocaleLowerCase());
    return str
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
}

export function nl2br(str: string) {
    return str.replace(/\n+/g, '<br />');
}

export function limitText(str: string, limit: number) {
    if (str.length > limit) {
        return str.substr(0, limit) + '…';
    }
    return str;
}

export function pageTitle(title: string) {
    return `MediaArchiver ›› ${title}`;
}

export function replaceDiacritics(str: string){

    var diacritics = [
      {char: 'A', base: /[\300-\306]/g},
      {char: 'a', base: /[\340-\346]/g},
      {char: 'E', base: /[\310-\313]/g},
      {char: 'e', base: /[\350-\353]/g},
      {char: 'I', base: /[\314-\317]/g},
      {char: 'i', base: /[\354-\357]/g},
      {char: 'O', base: /[\322-\330]/g},
      {char: 'o', base: /[\362-\370]/g},
      {char: 'U', base: /[\331-\334]/g},
      {char: 'u', base: /[\371-\374]/g},
      {char: 'N', base: /[\321]/g},
      {char: 'n', base: /[\361]/g},
      {char: 'C', base: /[\307]/g},
      {char: 'c', base: /[\347]/g}
    ]
  
    diacritics.forEach(letter => str = str.replace(letter.base, letter.char));
  
    return str;
  };