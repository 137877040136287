import * as bunyan from 'browser-bunyan';

export enum Environment {
    DEV = 'dev',
    STAGING = 'staging',
    PRODUCTION = 'production',
}

export interface IConfig {
    environment: Environment;
    // Backend API URL
    backendAPIURL: string;
    // How many retries we should do for WS reconnection backoff, -1 for unlimited
    // The delay starts at 5 seconds and is doubled every try until capping reached.
    backendRetries: number;
    // Backend retry delay capping, in milliseconds
    backendRetriesCapping: number;
    // Backend retry starting delay
    backendRetriesStart: number;

    UsersUrl: string;
    // Loglevel
    logLevel: 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'fatal';
}

export let Config: IConfig;
console.log("process.env.REACT_APP_STAGE", process.env.REACT_APP_STAGE)
switch (process.env.REACT_APP_STAGE) {
    case Environment.PRODUCTION:
        // tslint:disable: no-console
        if (('console' in window) && (typeof console.log === 'function')) {
            console.log('Using production build');
        }
        // tslint:disable: no-console
        Config = {
            // No leading / !
            backendAPIURL: 'https://ws-club40.yacast.fr',
            UsersUrl: 'https://users.yacast.fr',
            backendRetries: -1,
            backendRetriesCapping: 1000,
            backendRetriesStart: 1000,
            environment: Environment.PRODUCTION,
            logLevel: 'debug',
        };
        break;
    case Environment.STAGING:
        // tslint:disable: no-console
        if (('console' in window) && (typeof console.log === 'function')) {
            console.log('Using development build');
        }
        // tslint:disable: no-console
        Config = {
            // No leading / !
            backendAPIURL: 'https://ws-club40-stg.yacast.fr',
            UsersUrl: 'https://users.yacast.fr',
            backendRetries: -1,
            backendRetriesCapping: 1000,
            backendRetriesStart: 1000,
            environment: Environment.STAGING,
            logLevel: 'debug',
        };
        break;
    case Environment.DEV:
        // tslint:disable: no-console
        if (('console' in window) && (typeof console.log === 'function')) {
            console.log('Using development build');
        }
        // tslint:disable: no-console
        Config = {
            // No leading / !
            backendAPIURL: 'http://0.0.0.0:10006/',
            UsersUrl: 'https://users.yacast.fr',
            backendRetries: -1,
            backendRetriesCapping: 1000,
            backendRetriesStart: 1000,
            environment: Environment.DEV,
            logLevel: 'debug',
        };
        break;
    default:
        // tslint:disable: no-console
        if (('console' in window) && (typeof console.log === 'function')) {
            console.log('Using default build');
        }
        // tslint:disable: no-console
        Config = {
            // No leading / !
            backendAPIURL: 'http://127.0.0.1:10006',
            UsersUrl: 'https://users.yacast.fr',
            backendRetries: -1,
            backendRetriesCapping: 1000,
            backendRetriesStart: 1000,
            environment: Environment.DEV,
            logLevel: 'debug',
        };
}
