import * as React from 'react';
import * as MD from '@material-ui/core';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { Home } from '../Pages/Home';
import { Login } from '../Pages/Login';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { logout } from '../Store/User/Actions';
import { IUserState } from '../Store/User/Types';
import { IApplicationState } from '../Store';
import LiensPartenaires from '../Pages/LiensPartenaires';

const styles = MD.createStyles({
    container: {
        userSelect: 'none',
    },
});


interface IPropsFromState {
    user: IUserState;
}

interface IPropsFromDispatch {
    logout: typeof logout;
}

type AllProps =
    MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}>;


export class RouterComponent extends React.Component<AllProps> {
    public render(): React.ReactNode {
        return (
            <MuiPickersUtilsProvider
                moment={moment}
                utils={MomentUtils}
            >
                    <MD.CssBaseline />
                    <MD.Grid
                        container={true}
                        direction='row'
                        justifyContent='center'
                        alignItems='center'
                        style={{ height: '100%' }}
                    >
                    {
                      (true) ?
                        <Switch>
                          <Route exact={true} path='/' component={Home} />
                          <Route exact={true} path='/lienspartenaires' component={LiensPartenaires} />
                          <Route
                              exact={true}
                              path='/logout'
                              render={() => {
                                  // this.props.logout();
                                  return <Redirect to='/' />;
                              }}
                          />
                          <Route component={() => <div>Not Found</div>} />
                        </Switch> :
                        <Switch>
                            <Route component={Login} />
                        </Switch>
                      }
                    </MD.Grid>
            </MuiPickersUtilsProvider>
        );
    }
}


const mapStateToProps = ({ user }: IApplicationState) => ({
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: () => dispatch(logout()),
});


export const Router = MD.withStyles(styles)((
    connect(mapStateToProps, mapDispatchToProps)(RouterComponent)
) as any);
