import * as MD from '@material-ui/core';
import * as React from 'react';
import { Component } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IConnectedReduxProps, IApplicationState } from '../Store';

import { login } from '../Store/User/Actions';

import { IUserState } from '../Store/User/Types';

import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ReplyRoundedIcon from '@material-ui/icons/ReplyRounded';
import Logo from '../Assets/bloc-tete.jpg'
import { I18N } from '../Store/I18n/Types';
import { Config } from '../config';

interface IOwnState {
    username: string;
    password: string;
    open: boolean;
    list: string[];
}

interface IPropsFromState {
    user: IUserState;
    i18n: I18N;
    router: RouteComponentProps<{}>;
}

interface IPropsFromDispatch {
    login: typeof login;
}

interface IPropsTheme {
    theme: any;
}

type AllProps =
    MD.WithTheme &
    IPropsTheme &
    MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

const styles = (theme:any) => MD.createStyles({
    logo: {
        //position:'absolute', 
    },
    button: {
        margin: theme.spacing(1),
        marginTop: 25
    },
    card: {
        marginTop: '0px',
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        height: '230px'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        //flexWrap: 'wrap',
        //position: 'absolute',
        //right: 200,
        top: 20,
    },
    media: {
        height: 140,
    },
    textField: {
        margin: 8,
    },
    avatarSmall: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        alignItems:'center',
        width: theme.spacing(3),
        height: theme.spacing(3),
    }, 
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        alignItems:'center',
        color:"white"
    },
    paper: {
        //display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        },
        cardContent: {
            //position: 'absolute',
            //top: '0px',
            //right: '0px',
            backgroundColor: theme.palette.background.paper,
            width: '100%'
        },
        profileStyle: {
            color: theme.palette.text.primary,
            position:'relative',
            top:'0px',
            right:'25px'
        },
    alert: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        position: 'relative',
        right: 20,
        top: 97,
    },
});

export class LoginPage extends Component<AllProps, IOwnState> {
    public constructor(props: AllProps) {
        super(props);
        this.state = {
            password: '',
            username: '',
            open: true,
            list: []
        };
    }

    static getDerivedStateFromProps(props: AllProps, state: IOwnState) {
        
        return state
    } 
    public render(): React.ReactNode {
        const { classes, user, theme } = this.props;
        const { open } = this.state;
        return (
            <MD.Grid container direction='row' className={classes.card} style={{display: 'grid'}}>
                <MD.Grid item xs className={classes.logo} style={{justifyContent: 'center', display:'flex'}}>
                    <img src={Logo} alt='website logo'/>
                </MD.Grid>
                
                <MD.Grid item xs className={classes.cardContent} style={{justifyContent: 'center', display:'flex', paddingTop: 10}}>
                    <form
                        className={classes.form}
                        noValidate={true}
                        onSubmit={this.onSubmit.bind(this)}
                    >
                        <MD.Grid container spacing={1} alignItems="flex-end" >
                            <MD.Avatar className={classes.avatarSmall}>
                                <AccountCircleIcon />
                            </MD.Avatar>
                                <MD.Grid item>
                                <MD.TextField
                                    autoComplete='username'
                                    className={classes.textField}
                                    label={'Utilisateur'}
                                    onChange={this.onUsernameChange.bind(this)}
                                    value={this.state.username}
                                    InputLabelProps={{
                                        style:{
                                            color: theme.palette.text.primary,
                                            marginTop: '-6px'
                                        }
                                    }}
                                />
                            </MD.Grid>
                        </MD.Grid>
                        <MD.Grid style={{top: '65px'}}
                        container spacing={1} alignItems="flex-end">
                            <MD.Avatar className={classes.avatarSmall}>
                                <VpnKeyIcon />
                            </MD.Avatar>
                                <MD.Grid item>
                            <MD.TextField
                                autoComplete='off'
                                className={classes.textField}
                                label={'Mot de passe'}
                                onChange={this.onPasswordChange.bind(this)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        this.onSubmit();
                                    }
                                }}
                                type='password'
                                value={this.state.password}
                                InputLabelProps={{
                                    style:{
                                        color: theme.palette.text.primary,
                                        marginTop: '-6px',
                                        
                                    }
                                }}
                            />
                            </MD.Grid>
                        </MD.Grid>
                        <MD.Button
                            className={classes.button}
                            color='secondary'
                            onClick={this.onSubmit.bind(this)}
                            variant='contained'
                        >
                            {'Connexion'}
                        </MD.Button>
                        <MD.Grid item xs style={{textAlign:'end'}}>
                            <MD.Link target="_blank" href={`${Config.UsersUrl}/reset`} variant="body2">
                                    Mot de passe oublié ?
                            </MD.Link>
                        </MD.Grid>
                    </form>
                </MD.Grid>
                { 
                    user.errorMessage !== null &&
                    <Collapse in={open} className={classes.alert}>
                        <Alert  onClose={() => { this.setState({open:false}) }}
                        style={{backgroundColor: 'rgb(253, 236, 234)', color: 'rgb(97, 26, 21)'}}
                        severity="error"
                        >
                        <AlertTitle>Erreur</AlertTitle>
                        <strong> {user.errorMessage.message}</strong>
                        </Alert>
                    </Collapse>
                }
                <MD.Button
                    aria-haspopup='true'
                    className={classes.profileStyle}
                    onClick={() => window.open('https://www.yacast.fr/fr/','_self')}
                >
                    <MD.Avatar className={classes.avatarSmall}>
                        <ReplyRoundedIcon />
                    </MD.Avatar>
                    <span title={this.props.i18n._('Retourner sur Yacast.fr')} >{'Yacast.fr'}</span>
                </MD.Button>
                
            </MD.Grid>
       );
    }
    private onUsernameChange(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ username: ev.target.value });
    }

    private onPasswordChange(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ password: ev.target.value });
    }

    private onSubmit() {
        if (this.props.user.errorMessage !== null) {
            this.setState({open: true})
        }
        const pathName = this.props.router.location.pathname;
        this.props.login(this.state.username, this.state.password);
        if (pathName === '/' ) {
            //this.props.history.push('/nouveautes'); 
        }
        else this.props.history.push(pathName);
        return <Redirect to={pathName} />;
    }
}

const mapStateToProps = ({user, i18n } : IApplicationState, ownProps: RouteComponentProps<{}>) => {
    return {
      user: user,
      i18n: i18n.i18n,
      router: ownProps,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    login: (username: string, password: string) => dispatch(login(username, password)),
});

export const Login =
    connect(mapStateToProps, mapDispatchToProps)(
        MD.withTheme(MD.withStyles(styles)(LoginPage)),
    );
