import { action } from 'typesafe-actions';

import { IUserActionTypes } from './Types';

export const login = (username: string, password: string) => {
    return action(IUserActionTypes.LOGIN, [username, password])
};
export const getToken = (music: boolean, readjusted: boolean, username: string) => {
    return action(IUserActionTypes.GET_TOKEN, [music, readjusted, username])
}
export const getTokenSuccess = (expires:number, token: string) => {
    return action(IUserActionTypes.GET_TOKEN_SUCCESS, [expires, token])
} 
export const logout = () => action(IUserActionTypes.LOGOUT);
export const loginSuccess = (username: string, token: string, expires: number, user:any) =>
    action(IUserActionTypes.LOGIN_SUCCESS, [username, token, expires, user]);
export const loginError = (message:string) => action(IUserActionTypes.LOGIN_ERROR, message);
