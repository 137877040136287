import React, { Fragment } from "react";
import * as MD from "@material-ui/core";
import { usePagination, useSortBy, useTable } from "react-table";
import "../../react-table.css";
import { BiPlayCircle } from "react-icons/bi";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import _ from "lodash";
import { IUserState } from "../../Store/User/Types";
import { IApplicationState } from "../../Store";
import { capitalize } from "../../Utils/String";
import { I18N } from "../../Store/I18n/Types";
import { IClassementClub } from "../../Store/ClassementClub/Types";
import { DialogTitle, PaperComponent } from "../../Themes/StyledElements";
import { ILayoutState } from "../../Store/Layout/Types";

const Styles = styled.div `
  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid black;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      };
      font-weight: bold;
    border-bottom: 1px solid #9C9C9C;
    background-repeat: no-repeat;
    background: rgb(58, 58, 59);
    background: -moz-linear-gradient(top, #3a3a3b0%, #5c5b5b 80%, #5e5c5c 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#3a3a3b), color-stop(80%,#5c5b5b), color-stop(100%,#5e5c5c));
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3a3a3b', endColorstr='#5e5c5c',GradientType=0 );
    }
    th,
    td {
      margin: 0;
      padding: 2px;
      :last-child {
        border-right: 0;
      }
    }
  }
  
  .pagination {
    padding: 0.1rem;
    background-color: darkgray;
    display: block;
    text-align: center;
  }
`
const styles = (theme:any) => MD.createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      clear:"both",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    toggleDiv:{
      width:"100%",
    },
    closeButton: {
      position: 'absolute',
      right: '8px',
      top: '5px',
      color: theme.palette.grey[500],
    },
  });

interface IPropsFromState {
  i18n: I18N;
  user: IUserState;
  layout: ILayoutState;
}

interface IPropsFromDispatch {
}
  
interface IOwnProps {
  data: IClassementClub[],
  rowPlayingID?: number;
}

type AllProps =
    MD.WithTheme &
    MD.WithStyles &
    IPropsFromState &
    IPropsFromDispatch &
    IOwnProps;

interface IOwnState {
  originalData: any[];
  data: any[];
  columns: any[];
  currentTrackPlaying: any,
  openAudio: boolean;
  isPlaying: boolean;
}
interface ITableProps {
  columns: any,
  data: any,
  rowPlayingID: number
}

function hiddenColumns(columns:any) {
  let hiddenColumns:any = [] // array of id type string
  hiddenColumns = columns.filter((x:any) => x.show === false)
  hiddenColumns = hiddenColumns.map((x:any) => x.accessor)
  return hiddenColumns
}

function setThStyle(column:any) {
  if (column.id === 'date' || column.id === 'heure' || column.id === "duree") {
    return {
      ...column.headerStyle,
      width: '70px'
    }
  } else if (column.id === 'fonction' || column.id === 'intervenant') {
      return {
        ...column.headerStyle,
        width: '150px'
      }
  } else {
    return {
      ...column.headerStyle,
      backgroundColor: '#302f2f',
      color: 'white'
    }
  }
  
}

function setTrStyle(row:any, index: any, rowPlayingId?: number) {
  let style:any = {
    //backgroundColor: index % 2 === 0 ?  "rgb(58, 58, 59)" : "rgb(58, 58, 59, 0.97)",
    lineHeight: '15px',
    padding: 2,
    backgroundColor:"#555",
    color:"#f0f0f0",
    borderBottom:"1px solid #444",
    textShadow:"1px 1px 1px #000",
  }
  if (row.index === rowPlayingId) { 
    style= {
      ...style,
      //backgroundColor: 'gray'
    }
  }
  return style
}
function setTdStyle(cell:any) {
  return (
    {
      textAlign: cell.column.id === 'Rang' || cell.column.id === "Rang N-1"  || cell.column.id === "master_filepath"? 'center' : 'left'
    }
  )
}
function Table(props:ITableProps) { 
  let columns:any = props.columns
  let data:any = props.data
  const {
    getTableProps, 
    getTableBodyProps, 
    headerGroups, 
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = 
  useTable({
    columns, 
    data,
    initialState: {
      hiddenColumns: hiddenColumns(columns),
      pageIndex: 0, pageSize: 20 
    },
  },
  useSortBy,
  usePagination
  )

  const generateSortingIndicator = (column:any) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };
  // Render Data Table UI
  return (
    <Fragment>
    <table {...getTableProps()} 
    style={{
      borderColor: '#bdabab',
      background: 'rgb(218, 218, 218)',
      color: '#f6f1f1',
      fontWeight: 300,
    }}
    className="-striped -highlight">
      <thead>
        {headerGroups.map(headerGroup => {
          return (
            <tr {...headerGroup.getHeaderGroupProps()} >
              {headerGroup
                .headers
                .map((column:any, index:number) => {
                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    ...setThStyle(column)
                  }}
                  title=''
                  >
                    {column.render('Header')}
                    {/* {generateSortingIndicator(column)} */}
                  </th>
                )})
              }
            </tr>
          )}
        )}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          if (row !== null) {
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
              <tr
              style={setTrStyle(row, i, props.rowPlayingID)}
              >
                {row
                  .cells
                  .map((cell:any) => {
                    return (
                    <td {...cell.getCellProps()}
                    style={setTdStyle(cell) as any} 
                    >{cell.render('Cell')}</td>)
                  })}
              </tr>
              </Fragment>
            )
          } else return null
        })}
      </tbody>
    </table>
    
    </Fragment>
  )
}

export class TableDetailsComponent extends React.Component<AllProps, IOwnState> {
  private audioPlayerRef: React.RefObject<HTMLAudioElement>
    public constructor(props: AllProps) {
        super(props);
        this.audioPlayerRef = React.createRef();
        this.state = {
          originalData: [],
          data: [],
          columns: [],
          currentTrackPlaying: {filepath: '', id: 0},
          openAudio: false,
          isPlaying: false
        }
    }

    private handleAudioPlayerClick = (event:any, row:any) => {
      event.stopPropagation();
      event.preventDefault();
      let id = row.original.id;
      let master_filepath = row.original.master_filepath;
      
      this.setState({
        openAudio: true,
        currentTrackPlaying: {filepath: master_filepath, id: id},
      })
      if (this.audioPlayerRef && this.audioPlayerRef.current) {
        if (this.state.currentTrackPlaying.id === id && this.state.isPlaying){
          this.audioPlayerRef.current.pause();
          this.setState({isPlaying: false})
        } else {
          this.audioPlayerRef.current.src = master_filepath;
          this.setState({
            isPlaying: true,
            currentTrackPlaying: {filepath: master_filepath, id: id},
          })
        }
      }
    }

    private buildColumns() {
      const { i18n, theme } = this.props
      let dataCopy:any = []
      let headerStyle= {
        backgroundColor: "#dadada",
        color: 'black',
        flex: '100 0 auto',
        fontSize: '0.875rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 300,
        width: '150px',
        cursor: 'pointer'
      }
      this.props.data.map((x:IClassementClub) => {
        let jsonData:any = {}
        jsonData["pochette"] = x.pochette_filepath
        jsonData["Rang"] = x.rang
        jsonData["Rang N-1"] = x.rang_precedent
        jsonData["Title"] = x.titre 
        jsonData["Interprete"] = x.interprete
        jsonData["Label"] = x.label
        jsonData["Distributor"] = x.distributeur
        jsonData["master_filepath"] = x.master_filepath
        jsonData["id"] = x.id
        dataCopy.push(jsonData)
        return dataCopy
      })
      let columns:any = []
      dataCopy.map((elem:any) => {
        Object.keys(elem).map((key:any, index: number) => {
          if (key === "id") {
            columns[index] = {
              Header: '',
              accessor: key,
              show: false,
            }
          }
          else if (key === "pochette") {
            columns[index] = {
                Header: "",
                accessor: 'pochette',
                show: true,headerStyle: {
                  ...headerStyle,
                  width: '50px'
                },
                minWidth: 50,
                Cell: (cell:any) => {
                  return <div style={{display:'inline-flex'}} title={cell.value}>
                    {
                    <img style={{height: '50px'}} src={cell.value} alt="pochette"/>
                    }
                    </div>;
                },
            }
          } else if (key === "Rang" || key === "Rang N-1") {
            columns[index] = {
                Header: capitalize(i18n._(`${key}`)),
                accessor: key,
                show: true,
                headerStyle: {
                  ...headerStyle,
                  width: '50px'
                },
                minWidth: 50,
                Cell: (cell:any) => {
                  return  <span title={cell.value}>
                            {cell.value}
                          </span>
                }
            }
          }  else if (key === 'master_filepath') {
            
            columns[index] = {
                Header: "Ecoute",
                accessor: "master_filepath",
                show: true,
                headerStyle: {
                  ...headerStyle,
                  width: '60px'
                },
                minWidth: 60,
                Cell: (cell:any) => {
                    return (
                      <div style={{display: 'inline-flex'}}>
                        <MD.IconButton 
                        onClick={(event:any) => this.handleAudioPlayerClick(event, cell.row)}
                        >
                          <BiPlayCircle 
                          style={{ cursor: 'pointer', marginRight: '10px', fontSize:'smaller', color: theme.palette.primary.light }}
                          />
                        </MD.IconButton>
                        </div>
                    );
                },
              }
          } else {
              columns[index] = {
                Header: capitalize(i18n._(`${key}`)),
                accessor: key,
                show: true,
                headerStyle: headerStyle,
                Cell: (cell:any) => {
                  return  <span title={cell.value}>
                            {cell.value}
                          </span>
                }
              }
          } 
          return columns
        })
        return columns
      })
      
      this.setState({ columns: columns, data: dataCopy, originalData: dataCopy})
    }

    componentDidMount() {
      this.buildColumns()
    }

  getTrProps = (state:any, rowInfo:any, instance:any) => {
    if (rowInfo) {
      return {
        style: {
          height: '25px'
        }
      }
    }
    return {};
  }

  private handleCloseAudio = () => {
    this.setState({
      openAudio: false,
      isPlaying: false
    });
  };

  private onAudioEnded = () => this.setState({ currentTrackPlaying: {filepath:'', id:0} })

  private drawAudio(src:string){
    return (
      <MD.Dialog  
        onClose={this.handleCloseAudio.bind(this)} 
        open={true}
        maxWidth='sm'
        style={{overflow:'hidden'}}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        >
        <DialogTitle i18n={this.props.i18n} id="draggable-dialog-title" onClose={this.handleCloseAudio.bind(this)} layout={this.props.layout}>
        </DialogTitle>
        <MD.Card style={{width: '325px', height:'80px', padding:'2px 12px'}}>
        <audio id='player1' preload="auto" src={encodeURI(src)} onEnded={this.onAudioEnded} controls controlsList="nodownload" autoPlay>
        Audio tag not supported
        </audio>
        </MD.Card>
        </MD.Dialog>
    )
  }

  private drawTable() {
    return (
      this.state.data.length > 0 ?
      <Styles>
        <Table
          rowPlayingID={this.state.currentTrackPlaying.id}
          data={this.state.data} 
          columns={this.state.columns}
        />
      </Styles>    
      : <div>
            {this.props.i18n._('No results')}  
        </div>      
    )
  }

  render() {
    const { i18n } = this.props;
    return ( 
      <div id="details-table"> 
        <audio id='player' ref={this.audioPlayerRef} onEnded={this.onAudioEnded} hidden></audio>
        { this.drawTable() }
        {this.state.openAudio && this.drawAudio(this.state.currentTrackPlaying.filepath)}

      </div>
    )
  }
}

const mapStateToProps = ({i18n, user, layout} : IApplicationState) => {
  return {
    i18n: i18n.i18n,
    user,
    layout
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  
});

export const TableDetails = 
    connect(mapStateToProps, mapDispatchToProps)
    (MD.withTheme(MD.withStyles(styles)(TableDetailsComponent)));
