import * as effects from 'redux-saga/effects';

import { Backend } from '../../Backend/Backend';
import { Logger } from '../../Utils/Logger';
import { getClassementClub, getClassementClubSuccess, getClubs, getClubsSuccess, getDateCloture, getDateClotureSuccess, getHits, getHitsSuccess, sendEmailNewsLetter, sendEmailNewsLetterSuccess } from './Actions';
import { IClassementClubActionTypes } from './Types';



function* handleGetClassementClub(action: ReturnType<typeof getClassementClub>) {
    const backend = Backend.getInstance();
    try {
        const res = yield effects.apply(backend, backend.getClassementClub, [action.payload as any]);
        Logger.info('Successfully getClassementClub');
        if (res.status_code === 200) {
            yield effects.put(getClassementClubSuccess(res.results));
            yield effects.put(getHits(action.payload));
            yield effects.put(getClubs());
        }
        
    } catch (err) {
        Logger.warn({ err }, 'Failed getClassementClub');
    }
}

function* handleSendEmaiNewsletter(action: ReturnType<typeof sendEmailNewsLetter>) {
    const backend = Backend.getInstance();
    try {
        const res = yield effects.apply(backend, backend.sendEmailNewsletter, [action.payload as any]);
        Logger.info('Successfully sendEmailNewsletter');
        if (res.status_code === 200) {
            yield effects.put(sendEmailNewsLetterSuccess(res.results))
        }
        
    } catch (err) {
        Logger.warn({ err }, 'Failed sendEmailNewsletter');
    }
}

function* handleGetDateCloture(action: ReturnType<typeof getDateCloture>) {
    const backend = Backend.getInstance();
    try {
        const res = yield effects.apply(backend, backend.getDateCloture, []);
        Logger.info('Successfully getDateCloture');
        if (res.status_code === 200 && res.results && res.results.avancementBatchClub) {
            yield effects.put(getDateClotureSuccess(res.results.avancementBatchClub))
        }
        
    } catch (err) {
        Logger.warn({ err }, 'Failed getDateCloture');
    }
}
function* handleGetHits(action: ReturnType<typeof getHits>) {
    const backend = Backend.getInstance();
    try {
        const res = yield effects.apply(backend, backend.getHits, [action.payload as any]);
        Logger.info('Successfully getHits');
        if (res.status_code === 200) {
            yield effects.put(getHitsSuccess(res.results))
        }
        
    } catch (err) {
        Logger.warn({ err }, 'Failed getHits');
    }
}

function* handleGetClubs(action: ReturnType<typeof getClubs>) {
    const backend = Backend.getInstance();
    try {
        const res = yield effects.apply(backend, backend.getClubs, []);
        Logger.info('Successfully getClubs');
        if (res.status_code === 200) {
            yield effects.put(getClubsSuccess(res.results))
        }
        
    } catch (err) {
        Logger.warn({ err }, 'Failed getClubs');
    }
}

function* watchClassementClub() {
    yield effects.takeEvery(IClassementClubActionTypes.GET_CLASSEMENT_CLUB, handleGetClassementClub);
    yield effects.takeEvery(IClassementClubActionTypes.SEND_EMAIL_NEWSLETTER, handleSendEmaiNewsletter);
    yield effects.takeEvery(IClassementClubActionTypes.GET_HITS, handleGetHits);
    yield effects.takeEvery(IClassementClubActionTypes.GET_CLUBS, handleGetClubs);
    yield effects.takeEvery(IClassementClubActionTypes.GET_DATE_CLOTURE, handleGetDateCloture);
}

export function* ClassementClubSaga() {
    yield effects.all([
        effects.fork(watchClassementClub),
    ]);
}
