import { ReactNode } from 'react';
import { PaletteMode } from '../../Themes';

export enum LayoutActionTypes {
    DRAWER_SHOW = '@@layout/DRAWER_SHOW',
    DRAWER_HIDE = '@@layout/DRAWER_HIDE',
    SET_DRAWER_CONTENT = '@@layout/SET_DRAWER_CONTENT',
    SET_PAGE_CONTENT = '@@layout/SET_PAGE_CONTENT',
    TOGGLE_THEME = '@@layout/TOGGLE_THEME',
}

export interface ILayoutState {
    readonly drawerContent: ReactNode;
    readonly pageContent: ReactNode;
    readonly showDrawer: boolean;
    readonly theme: PaletteMode;
}
