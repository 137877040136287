import moment from 'moment';
import numeral from 'numeral';
import * as effects from 'redux-saga/effects';

import { Logger } from '../../Utils/Logger';
import { TypedStorage } from '../../Utils/TypedStorage';

import { loadError, loadSuccess, setLocale } from './Actions';
import { LocaleInfos, Locales } from './Constants';
import { I18nActionTypes, I18N } from './Types';

import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'numeral/locales/en-gb';
import 'numeral/locales/fr';

/*
  When https://github.com/Microsoft/TypeScript/pull/28207 is released ...
  import en_US from '../../I18n/en_US.json';
  import fr_FR from '../../I18n/fr_FR.json';
 */
/* tslint:disable */
const en_US = require('../../I18n/en_US.json');
const fr_FR = require('../../I18n/fr_FR.json');

const Jed = require('jed');

/* tslint:enable */

function* handleSetLocale(action: ReturnType<typeof setLocale>) {
    try {
        const res = yield effects.call(loadGettext, action.payload);

        if (res.error) {
            yield effects.put(loadError(res.error));
        } else {
            yield effects.put(loadSuccess(
                res,
                LocaleInfos[action.payload],
            ));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield effects.put(loadError(err.stack!));
        } else {
            yield effects.put(loadError('An unknown error occured.'));
        }
    }
}

async function loadGettext(locale: string): Promise<I18N> {
    let data: any;

    switch (locale) {
        case Locales.French:
            data = fr_FR;
            break;
        case Locales.English:
            data = en_US;
            break;
        default:
            return Promise.reject('Unknown locale');
    }

    let gettext: any;
    try {
        gettext = new Jed({
            domain: 'messages',
            locale_data: data.locale_data,
            missing_key_callback: (key: string) => {
                // Logger.warn(key);
            },
        });
        moment.locale(LocaleInfos[locale].momentLocale);
        numeral.locale(LocaleInfos[locale].momentLocale);
        TypedStorage.set('locale', locale);
    } catch (err) {
        Logger.warn('Could not load translations', { err });
        return Promise.reject(err);
    }

    return Promise.resolve({
        _: gettext.gettext.bind(gettext),
        gettext: gettext.gettext.bind(gettext),
        ngettext: (msgId: string, plural: string, n: number) =>
            Jed.sprintf(gettext.ngettext(msgId, plural, n), n),
        sprintf: Jed.sprintf,
    });
}

function* watchLoad() {
    yield effects.takeEvery(I18nActionTypes.SET_LOCALE, handleSetLocale);
}

export function* I18NSaga() {
    yield effects.all([
        effects.fork(watchLoad),
    ]);
}
