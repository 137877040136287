 import { AxiosResponse } from 'axios';
import { IContentDisposition } from './Types';

export class StatusCodeError extends Error {
    public code: number;
    public body: string;
    public reason: string;

    constructor(code: number, body: string) {
        super(`Unexpected status code: ${code}`);
        Object.setPrototypeOf(this, StatusCodeError.prototype);
        this.code = code;
        this.body = body;
        this.reason = '';

        try {
            const data = JSON.parse(this.body);

            if ((data.error === true) && (typeof data.reason === 'string')) {
                this.reason = data.reason;
            }
        } catch (err) { /**/ }
    }
}

/**
 * Returns a promise of fetch() result as arraybuffer or a StatusCodeError if status code is not 20x
 */
export async function StatusCodeFilterAxios(res: AxiosResponse<any>): Promise<ArrayBuffer> {
    if (res.status < 200 || res.status > 299) {
        if (res.status === 401) {
            window.document.location.href = '/logout';
            return Promise.reject(new Error('You are not authentificated'));
        }
        throw new StatusCodeError(res.status, res.data);
    }
    return res.data;

}

export async function StatusCodeFilter(res: Response): Promise<ArrayBuffer> {
    if (res.status < 200 || res.status > 299) {
        if (res.status === 401) {
            window.document.location.href = '/logout';
            return Promise.reject(new Error('You are not authentificated'));
        } else if (res.status === 403) {
            return res.arrayBuffer();
        }
        return res.text()
            .then((body: string) => {
                throw new StatusCodeError(res.status, body);
            });
    }
    return res.arrayBuffer();

}
