import { action } from 'typesafe-actions';

import { IGenerateFileActionTypes } from './Types';

export const generateFileXML = (date_current_cloture: Date) => {
    return action(IGenerateFileActionTypes.GENERATE_FILE_XML, date_current_cloture)
};
export const generateFileXMLSuccess = (results: any) => {
    return action(IGenerateFileActionTypes.GENERATE_FILE_XML_SUCCESS, results)
};
export const generateFileXMLError = (err: any) => {
    return action(IGenerateFileActionTypes.GENERATE_FILE_XML_ERROR, err)
};