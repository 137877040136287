import { Reducer } from 'redux';
import { IGenerateFileActionTypes, IGenerateFileState } from './Types';

let initialState: IGenerateFileState = {
    xml_response: ''
};


const reducer: Reducer<IGenerateFileState> = (state = initialState, action) => {
    switch (action.type) {
        case IGenerateFileActionTypes.GENERATE_FILE_XML: {
            return {
                ...state,
                xml_response: ''
            }
        }
        case IGenerateFileActionTypes.GENERATE_FILE_XML_SUCCESS: {
            let results = action.payload;
           
            return {
                ...state,
                xml_response: results
            }
        }
        case IGenerateFileActionTypes.GENERATE_FILE_XML_ERROR: {
            return {
                ...state,
                xml_response: ''
            }
        }
        default: {
            return state;
        }
    }
};

export { reducer as GenerateFileReducer };
