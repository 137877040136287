import { action } from 'typesafe-actions';

import { IClassementClubActionTypes } from './Types';

export const getClassementClub = (date_current_cloture: Date) => {
    return action(IClassementClubActionTypes.GET_CLASSEMENT_CLUB, date_current_cloture)
};
export const getClassementClubSuccess = (results: any) => {
    return action(IClassementClubActionTypes.GET_CLASSEMENT_CLUB_SUCCESS, results)
};
export const getClassementClubError = (err: any) => {
    return action(IClassementClubActionTypes.GET_CLASSEMENT_CLUB_ERROR, err)
};

export const sendEmailNewsLetter = (email: string) => {
    return action(IClassementClubActionTypes.SEND_EMAIL_NEWSLETTER, email)
};
export const sendEmailNewsLetterSuccess = (results: any) => {
    return action(IClassementClubActionTypes.SEND_EMAIL_NEWSLETTER_SUCCESS, results)
};
export const sendEmailNewsLetterError = (err: any) => {
    return action(IClassementClubActionTypes.SEND_EMAIL_NEWSLETTER_ERROR, err)
};

export const getHits = (date_current_cloture: Date) => {
    return action(IClassementClubActionTypes.GET_HITS, date_current_cloture)
};
export const getHitsSuccess = (results: any) => {
    return action(IClassementClubActionTypes.GET_HITS_SUCCESS, results)
};
export const getHitsError = (err: any) => {
    return action(IClassementClubActionTypes.GET_HITS_ERROR, err)
};
export const getClubs = () => {
    return action(IClassementClubActionTypes.GET_CLUBS, )
};
export const getClubsSuccess = (results: any) => {
    return action(IClassementClubActionTypes.GET_CLUBS_SUCCESS, results)
};
export const getClubsError = (err: any) => {
    return action(IClassementClubActionTypes.GET_CLUBS_ERROR, err)
};
export const saveSelectedDateWeekNumber = (selected_date: Date, week_number: number) => action(IClassementClubActionTypes.SAVE_SELECTED_DATE_WEEK_NUMBER, [selected_date, week_number]);

export const getDateCloture = () => {
    return action(IClassementClubActionTypes.GET_DATE_CLOTURE)
};
export const getDateClotureSuccess = (results: any) => {
    return action(IClassementClubActionTypes.GET_DATE_CLOTURE_SUCCESS, results)
};
export const getDateClotureError = (err: any) => {
    return action(IClassementClubActionTypes.GET_DATE_CLOTURE_ERROR, err)
};