import * as React from 'react';
import * as MD from '@material-ui/core';
import { Route } from 'react-router';
import { Router } from './Router';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { Dispatch, Store } from 'redux';
import { IApplicationState } from '../Store';
import { connect, Provider } from 'react-redux';

import { setLocale } from '../Store/I18n/Actions';
import { Locales } from '../Store/I18n/Constants';
import { getTheme, PaletteMode } from '../Themes';

import { TypedStorage } from '../Utils/TypedStorage';

interface IPropsFromDispatch {
  [key: string]: any;
}

interface IOwnProps {
  store: Store<IApplicationState>;
  history: History;
}

type AllProps = IPropsFromDispatch & IOwnProps;

class MainComponent extends React.Component<AllProps> {

  public componentDidMount() {
    this.props.loadI18n(TypedStorage.get('locale', Locales.French));
  }

  public render() {
    const { store, history } = this.props;
    return (  
      <MD.MuiThemeProvider theme={getTheme(this.props.theme)}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Route component={Router} />
          </ConnectedRouter>
        </Provider>
      </MD.MuiThemeProvider>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadI18n: (locale: Locales) => dispatch(setLocale(locale)),
});

const mapStateToProps = ({ layout }: IApplicationState) => ({
  theme: layout.theme,
});


const Main = connect<{}, IPropsFromDispatch, IOwnProps, IApplicationState>(
  mapStateToProps, mapDispatchToProps,
)(MainComponent);

export { Main };
