import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { styled, Theme } from '@material-ui/core/styles';

//Store
import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { I18N } from '../../Store/I18n/Types';
import { IClassementClub, IClassementClubState } from '../../Store/ClassementClub/Types';
import { CustomTooltip, DialogTitle, PaperComponent, TableCell, TableHeaderCell } from '../../Themes/StyledElements';
import PauseCircleOutlineOutlinedIcon from '@material-ui/icons/PauseCircleOutlineOutlined';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import { ILayoutState } from '../../Store/Layout/Types';
import { Box, Card, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';
import { changePochetteFilepath } from '../../Store/ClassementClub/Reducer';

import { withStyles, createStyles } from '@material-ui/core/styles';
import { StyledGrid } from './UpcomingHits';

const styles = (theme: Theme) => MD.createStyles({
  paperOverflow: {
    background: 'none',
    boxShadow: 'none'
  },
  tableContainer: {
      padding: '0px',
      //overflow: 'auto',
      //maxHeight: 'calc(100vh - 110px)',
  },
  rootHead: {
    width: '100%',
    display:'inline-table',
    position:'sticky',
    top:'0px',
    zIndex:1
  },
  playIcon: {
    cursor: 'pointer', marginRight: '10px', color: theme.palette.secondary.main
  },
});

interface IPropsFromState {
  i18n: I18N;
  classementClub: IClassementClubState;
  layout: ILayoutState;
}

interface IPropsTheme {
  theme: any;
}

interface IPropsFromDispatch {
}

type AllProps =
    MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    IPropsTheme &
    IConnectedReduxProps;


interface IState {
  currentTrackPlaying: any,
  openAudio: boolean;
  isPlaying: boolean;
}

export class ClubMembersComponent extends React.Component<AllProps, IState> {
    private audioPlayerRef: React.RefObject<HTMLAudioElement>
    public constructor(props: AllProps) {
        super(props);
        this.audioPlayerRef = React.createRef();
        this.state = {
          currentTrackPlaying: {filepath: '', id: 0},
          openAudio: false,
          isPlaying: false
        };
    }

    public componentDidMount() {
    }

    static getDerivedStateFromProps(props: AllProps, state: IState) {
      return state;
    }

    private drawClubElem(elem:any) {
      return (
       
                <MD.Grid container direction="row" style={{justifyContent:"center",
                alignItems:"center"}} >
                
                        <Typography component="div">
                        {elem}
                        </Typography>
                
                </MD.Grid>
      )
    }

    private drawClubsContent() {
        return (
            <MD.Paper style={{marginTop: 40, borderRadius: 8}}>
                <Grid container spacing={3} direction={'column'}>
                    
                    <StyledGrid item xs style={{marginLeft: "10px", paddingBottom: 0, height: "36px", minHeight: "36px"}}>
                        <Typography  style={{fontWeight: "bold", fontSize: 16, color: 'white'}}>{this.props.i18n._('Les clubs membres du panel')}</Typography>
                    </StyledGrid>
                    <Grid item xs>
                        <Grid container spacing={3} direction={'row'}>
                            <Grid item xs style={{marginLeft: 20}}>
                                {this.drawClubElem(this.props.classementClub.clubs_members)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MD.Paper>
        )
    }

    public render(): React.ReactNode {
        const { classes, i18n } = this.props;
        return(
        <>
          { this.props.classementClub.get_clubs_loading ? 
            <MD.Grid
            style={{margin: '16px',width: '100%',
            position:'relative'}}>
                <MD.CircularProgress color='secondary'/>
            </MD.Grid>  
          : 
            this.drawClubsContent() 
          }
        </>
        )
    }
}

const mapStateToProps = ({ i18n, classementClub, layout }: IApplicationState) => ({
    i18n: i18n.i18n,
    classementClub,
    layout
});


const mapDispatchToProps = (dispatch: Dispatch) => ({
});

export const ClubMembers =
  connect(mapStateToProps, mapDispatchToProps)(MD.withTheme(MD.withStyles(styles)(ClubMembersComponent)));