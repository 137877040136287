import { StatusCodeError, StatusCodeFilter, StatusCodeFilterAxios } from './StatusCodeError';
import { Config } from '../config';
import { Logger } from '../Utils/Logger';
import { IAuthSuccess, IContentDisposition } from './Types';
import moment from 'moment';
import { adjustForTimezone, fromUTC, getDateHourZero } from '../Utils/Time';


export type BackendMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
export class Backend {
    public static getInstance() {
        if (!Backend.instance) {
            Backend.instance = new Backend();
        }
        return Backend.instance;
    }

    private static instance: Backend;
    private token: string = '';
    
    public setToken(tok: string) {
        this.token = tok;
    }

    isAuthSuccess(object: any): object is IAuthSuccess {
        return (
            ('token' in object) &&
            (typeof object.token === 'string')
        );
    }

    private async callBackend(
        route: string,
        method: BackendMethod = 'GET',
        body: ArrayBuffer | FormData | null = null,
        addHeaders: Headers | null = null
    ): Promise<ArrayBuffer> {
        const url = ((route.length === 0) || (route[0] !== '/')) ? `${Config.backendAPIURL}/${route}` : `${Config.backendAPIURL}${route}`;
  
        const headers = (addHeaders === null) ?
        this.token ?
            new Headers({
                'Authorization': 'Bearer ' + this.token,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }) :
            new Headers({
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*'
            }) : addHeaders;
        return fetch(
            url,
            {
                body,
                cache: 'no-cache',
                headers,
                method,
                mode: 'cors',
                redirect: 'error',
            },
        ).then(StatusCodeFilter);
    }

    private async callBackendNoStatusCodeFilter(
      route: string,
      method: BackendMethod = 'GET',
      body: ArrayBuffer | FormData | null = null,
      addHeaders: Headers | null = null,
  ): Promise<Response> {
      const url = ((route.length === 0) || (route[0] !== '/')) ?
          `${Config.backendAPIURL}/${route}` :
          `${Config.backendAPIURL}${route}`;

      const headers = (addHeaders === null) ?
          new Headers({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }) : addHeaders;

      return fetch(
          url,
          {
              body,
              cache: 'no-cache',
              headers,
              method,
              mode: 'cors',
              redirect: 'error',
          },
      ).then((res: Response) => res);
  }

    private async callBackendJSON(
        route: string,
        obj: any,
        addHeaders: Headers | null = null,
        method: BackendMethod = 'POST'
    ): Promise<ArrayBuffer> {
        const data = this.obj2ab(obj);
        const headers = this.token ?
            new Headers({
                'Authorization': 'Bearer ' + this.token,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }) :
        (addHeaders === null) ? new Headers({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }) : addHeaders;
        headers.append('content-type', 'application/json');
        return this.callBackend(route, method, data, headers);
    }

    public async login(username: string, password: string) {
        const headers = new Headers();
        headers.append('content-type', 'application/json');
        
        return this.callBackendNoStatusCodeFilter(
            'auth/',
            'POST',
            this.obj2ab({ username, password }),
            headers
        ).then((res: any) => {
          if (res.status === 401)  {
            throw new Error('Authentication failed');
          }
          
          let contentDisposition = res.headers.get('content-disposition');
          if (contentDisposition) {
              return {
                  content: res.blob(),
                  contentDisposition: contentDisposition 
              }
          }
          return res.arrayBuffer();
        })
        .then(this.ab2obj).then((res: any) => {
            if (this.isAuthSuccess(res)) {
                this.token = res.token;
                return [res.token, res.expires * 1000, res.results];
            }
           
            throw new Error('Invalid response from backend');
        });
    }

    public async logout() {
        return this.callBackendJSON(
            '/auth/logout',
            { }
        )
            .then(this.ab2obj)
            .then((res: any) => {
                return res
            });
    }


    public async generateFile(date_current_cloture: Date, type:string) {
        let date = moment(fromUTC(getDateHourZero(new Date(date_current_cloture)))).format("DD/MM/YYYY HH:mm:ss")
        return this.callBackend(
            'export_file?type='+type+"&date_current_cloture="+date, 
            'GET', 
            ).then(this.ab2obj)
            .then((res) => {
          if (true) {//res && res.results
            return res
          }
          return {};
        });
    }
    public async getClassementClub(date_current_cloture: Date) {
        let date = moment(fromUTC(getDateHourZero(new Date(date_current_cloture)))).format("DD/MM/YYYY HH:mm:ss")
        return this.callBackend(
            'classementclub', 
            'POST', 
            this.obj2ab({'date_current_cloture': date})
            ).then(this.ab2obj)
            .then((res) => {
          if (res && res.results) {
            return res
          }
          return {};
        });
    }

    public async sendEmailNewsletter(email: string) {
        return this.callBackend(
            'classementclub/email_newsletter', 
            'POST', 
            this.obj2ab({'email': email})
            ).then(this.ab2obj)
            .then((res) => {
          return res
        });
    }

    public async getDateCloture() {
        return this.callBackend(
            'classementclub/get_date_cloture', 
            'GET', 
            ).then(this.ab2obj)
            .then((res) => {
          return res
        });
    }

    public async getHits(date_current_cloture: Date) {
        let date = moment(fromUTC(getDateHourZero(new Date(date_current_cloture)))).format("DD/MM/YYYY HH:mm:ss")
        return this.callBackend(
            'classementclub/get_hits', 
            'POST', 
            this.obj2ab({'date_cloture': date})
            ).then(this.ab2obj)
            .then((res) => {
          if (res && res.results) {
            return res
          }
          return {};
        });
    }

    public async getClubs() {
        return this.callBackend(
            'classementclub/get_clubs', 
            'GET'
            ).then(this.ab2obj)
            .then((res) => {
          if (res && res.results) {
            return res
          }
          return {};
        });
    }

    // // converts an object to an array buffer
    private obj2ab(obj: any): ArrayBuffer {
        return (new TextEncoder().encode(JSON.stringify(obj)));
    }

    // converts an array buffer to object
    private ab2obj(from: ArrayBuffer): any {
        const json = new TextDecoder().decode(new Uint8Array(from));

        return JSON.parse(json);
    }
}