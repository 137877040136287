import { ReactNode } from 'react';
import { action } from 'typesafe-actions';
import { PaletteMode } from '../../Themes';

import { LayoutActionTypes } from './Types';

export const hideDrawer = () => action(LayoutActionTypes.DRAWER_HIDE);
export const setDrawerContent = (content: ReactNode | null) => action(LayoutActionTypes.SET_DRAWER_CONTENT, content);
export const setPageContent = (content: ReactNode) => action(LayoutActionTypes.SET_PAGE_CONTENT, content);
export const showDrawer = () => action(LayoutActionTypes.DRAWER_SHOW);
export const toggleTheme = () => action(LayoutActionTypes.TOGGLE_THEME);