import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Theme } from '@material-ui/core/styles';

//Store
import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { I18N } from '../../Store/I18n/Types';
import { IClassementClub, IClassementClubState } from '../../Store/ClassementClub/Types';
import { CustomTooltip, DialogTitle, PaperComponent, TableCell, TableHeaderCell } from '../../Themes/StyledElements';
import PauseCircleOutlineOutlinedIcon from '@material-ui/icons/PauseCircleOutlineOutlined';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import { ILayoutState } from '../../Store/Layout/Types';
import { Card, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';
import { changePochetteFilepath } from '../../Store/ClassementClub/Reducer';
import { UpcomingHits } from './UpcomingHits';
import { HitsTable } from './HitsTable';
import { ClubMembers } from './ClubMembers';
import { TableDetails } from './TableDetails';
import Footer from './Footer';
import { toLocaleDateString } from '../../Utils/Time';
import { PaletteMode } from '../../Themes';
import moment from 'moment';

const styles = (theme: Theme) => MD.createStyles({
  
  infos:{
    fontSize: '16px',
    fontWeight: 600,
    backgroundColor: theme.palette.background.paper,
    padding: '10px 10px 5px',
},
  paperOverflow: {
    background: 'none',
    boxShadow: 'none'
  },
  tableContainer: {
      padding: '0px',
      //overflow: 'auto',
      //maxHeight: 'calc(100vh - 110px)',
  },
  rootHead: {
    width: '100%',
    display:'inline-table',
    position:'sticky',
    top:'0px',
    zIndex:1
  },
  playIcon: {
    cursor: 'pointer', marginRight: '10px', color: theme.palette.secondary.main
  },
});

interface IPropsFromState {
  i18n: I18N;
  classementClub: IClassementClubState;
  layout: ILayoutState;
}

interface IPropsTheme {
  theme: any;
}

interface IPropsFromDispatch {
}

type AllProps =
    MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    IPropsTheme &
    IConnectedReduxProps;


interface IState {
  currentTrackPlaying: any,
  openAudio: boolean;
  isPlaying: boolean;
}


export class DashboardContentComponent extends React.Component<AllProps, IState> {
    private audioPlayerRef: React.RefObject<HTMLAudioElement>
    public constructor(props: AllProps) {
        super(props);
        this.audioPlayerRef = React.createRef();
        this.state = {
          currentTrackPlaying: {filepath: '', id: 0},
          openAudio: false,
          isPlaying: false
        };
    }

    public componentDidMount() {
    }

    static getDerivedStateFromProps(props: AllProps, state: IState) {
      return state;
    }

    private drawResultsTableHeaders() {
      let headCells: any = [];
      headCells = [
        {id: 0, numeric: false, disablePadding: true, label: '', fieldValue: null},
        {id: 1, numeric: false, disablePadding: true, label: 'Rang', fieldValue: null},
        {id: 2, numeric: false, disablePadding: true, label: 'Rang N-1', fieldValue: ''},
        {id: 3, numeric: false, disablePadding: true, label: this.props.i18n._('Title'), fieldValue: ''},
        {id: 4, numeric: false, disablePadding: true, label: this.props.i18n._('Interprete'), fieldValue: ''},
        {
          id: 5,
          numeric: false,
          disablePadding: true,
          label: this.props.i18n._('Label'), 
          fieldValue: ''
        },
        {id: 6, numeric: false, disablePadding: true, label: this.props.i18n._('Distributor'), fieldValue: ''},
        {id: 7, numeric: false, disablePadding: true, label: this.props.i18n._('BPM'), fieldValue: ''},
        {id: 8, numeric: false, disablePadding: true, label: this.props.i18n._('Play'), fieldValue: ''},

      ];
      return (
        <MD.TableHead>
          <MD.TableRow >
              {headCells.map((headCell: any, index: number) => {
                return (
                  <TableHeaderCell
                    key={index}
                    style={{textTransform: headCell.transform ? 'none' : 'uppercase', whiteSpace:'nowrap'}}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                  >
                      <MD.Grid container direction="row" alignItems="center" wrap="nowrap"
                      style={{fontSize: '10px'}}>
                        {headCell.label}
                      </MD.Grid>
                  </TableHeaderCell>
                )
              }
            )}
          </MD.TableRow>
        </MD.TableHead>
      )
    }

    private handleAudioPlayerClick = (event:any,item:IClassementClub) => {
      event.stopPropagation();
      this.setState({
        openAudio: true,
        currentTrackPlaying: {filepath: item.master_filepath, id: item.id},
      })
      if (this.audioPlayerRef && this.audioPlayerRef.current) {
        if (this.state.currentTrackPlaying.id === item.id && this.state.isPlaying){
          this.audioPlayerRef.current.pause();
          this.setState({isPlaying: false})
        } else {
          this.audioPlayerRef.current.src = item.master_filepath;
          this.setState({
            isPlaying: true,
            currentTrackPlaying: {filepath: item.master_filepath, id: item.id},
          })
        }
      }
    }

    private drawResultsTableRow(item: IClassementClub, index: number) {
      const { theme, classes } = this.props;
      return (
        <MD.TableRow key={index} 
        style={{backgroundColor: 
          (this.state.currentTrackPlaying.id === item.id
          && this.audioPlayerRef.current
          && this.state.isPlaying
          && item.master_filepath !== "") ? 
          theme.palette.background.paper : theme.palette.background.default,
          }}>
          <TableCell align='center'>
          <img style={{height: '50px'}} src={(item.pochette_filepath)} alt=""/>

          </TableCell>

          <TableCell align='center'>
            {item.rang ? item.rang.toString() : ''}
          </TableCell>
          
          <TableCell  align='center'>
              {item.rang_precedent ? item.rang_precedent.toString() : ''}
          </TableCell>

          <TableCell>
              {item.titre}
          </TableCell>

          <TableCell>
              {item.interprete }
          </TableCell>
            <TableCell>
                {item.label}
            </TableCell>
          <TableCell>
              {item.distributeur}
          </TableCell>

          <TableCell align='center'>
              {item.bpm === null ? "--" : item.bpm.toString()}
          </TableCell>

          <TableCell>
            <MD.IconButton
            style={{ padding: 0, marginLeft: 5 }}
            onClick={(event:any) => this.handleAudioPlayerClick(event, item)}
            >
              {
                this.state.currentTrackPlaying.id === item.id
                && this.audioPlayerRef.current
                && this.state.isPlaying 
                ?
                <PauseCircleOutlineOutlinedIcon className={classes.playIcon} style={{fontSize: 'smaller'}} />
                :
                <CustomTooltip title= {this.props.i18n._("Play master")}>
                  <PlayCircleFilledWhiteIcon className={classes.playIcon} style={{fontSize: 'smaller'}}/>
                </CustomTooltip>
              }
            </MD.IconButton>
          </TableCell>
        </MD.TableRow>
      )
    }

    private drawTable() {
      const { classes, classementClub } = this.props;
      return (
        <MD.Paper className={classes.paperOverflow}>
          <MD.TableContainer className={classes.tableContainer} >
            <MD.Table stickyHeader aria-label="sticky table"  style={{borderCollapse: 'collapse' }}>
              {this.drawResultsTableHeaders()}
              <MD.TableBody>
                {classementClub.classement_club_results.map((item: IClassementClub, index: number) => {
                    return this.drawResultsTableRow(item, index);
                  }
                )}
              </MD.TableBody>
            </MD.Table>
          </MD.TableContainer>
        </MD.Paper>
      )
    }

    private drawHitElem(elem:any, entree:boolean) {
      return (
        <MD.Grid item xs>
            <MD.Card>
            <MD.Grid container direction="row" style={{justifyContent:"center",
            alignItems:"center"}} >
              <MD.Grid item xs={2}>
              <CardContent>
                <Typography component="div">
                  {entree ? this.props.i18n._('Meileure entrée du top' ) : this.props.i18n._('Meileure progression du top' )}
                </Typography>
              </CardContent>
              </MD.Grid>
              <MD.Grid item xs={4} style={{textAlign: 'center',
              display: 'flex',
              justifyContent: 'center'}}>
              <CardMedia
                component="img"
                style={{ width: 60, height: 60 }}
                image={changePochetteFilepath(elem.referencetitre)}
                alt="Live from space album cover"
              />
              </MD.Grid>
              <MD.Grid item xs={6}>
              <CardContent>
                  <Typography component="div">
                    {elem.nominterprete}
                  </Typography>
                  <Typography component="div">
                    {elem.titremusical}
                  </Typography>
                  <Typography component="div">
                    {elem.nomdistributeur}/{elem.nomlabel}
                  </Typography>
                  <Typography component="div">
                    {entree ? `${this.props.i18n._('Entree directe à la ')} ${elem.rangactuel}${"e"} ${this.props.i18n._('place')}`  : `+${elem.evorang}`}
                  </Typography>
              </CardContent>
              </MD.Grid>
            </MD.Grid>
            </MD.Card>
        </MD.Grid>
      )
    }

    private drawHitsContent() {
      return (
        <MD.Grid container direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}>
          {this.drawHitElem(this.props.classementClub.upcoming_hits.ENTREE[0], true)}
          {this.drawHitElem(this.props.classementClub.upcoming_hits.DIFFUSION[0], false)}
        </MD.Grid>
        
      )
    }

    private drawUpcomingHits() {
      return (
        <div>
          {this.props.classementClub.get_hits_loading ? 
          <div>
            {this.props.i18n._('Loading')}...
          </div> : 
            this.drawHitsContent() 
          /* <div>
            {this.props.classementClub.upcoming_hits.DIFFUSION.length > 0 && 
            <div>
              Label: {this.props.classementClub.upcoming_hits.DIFFUSION[0].nomlabel}
            </div>}
          </div> */
          }
        </div>
      )
    }

    private handleCloseAudio = () => {
      this.setState({
        openAudio: false,
        isPlaying: false
      });
    };

    private onAudioEnded = () => this.setState({ currentTrackPlaying: {filepath:'', id:0} })

    private drawAudio(src:string){
      return (
        <MD.Dialog  
          onClose={this.handleCloseAudio.bind(this)} 
          open={true}
          maxWidth='sm'
          style={{overflow:'hidden'}}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          >
          <DialogTitle i18n={this.props.i18n} id="draggable-dialog-title" onClose={this.handleCloseAudio.bind(this)} layout={this.props.layout}>
          </DialogTitle>
          <MD.Card style={{width: '325px', height:'80px', padding:'2px 12px'}}>
          <audio id='player1' preload="auto" src={encodeURI(src)} onEnded={this.onAudioEnded} controls controlsList="nodownload" autoPlay>
          Audio tag not supported
          </audio>
          </MD.Card>
          </MD.Dialog>
      )
    }

    private drawContent() {
      return (
        <MD.Grid container style={{ paddingTop: 20, width: "800px" }} direction={'column'}>
          <MD.Grid item xs>
            {/* <HitsTable {...this.props as any}/> */}
            <TableDetails 
            data={ this.props.classementClub.classement_club_results }
            />
          </MD.Grid>
          <MD.Grid item xs>
            <UpcomingHits {...this.props as any}/>
          </MD.Grid>
          <MD.Grid item xs>
            <ClubMembers {...this.props as any}/>
          </MD.Grid>
          <MD.Grid item xs>
            <Footer/>
          </MD.Grid>
        </MD.Grid>
      )
    }

    private drawWeekInfos() {
      const { classes, theme } = this.props;
      return (
        <div id="infos" className={classes.infos}
        style={{color: theme.palette.type === PaletteMode.DARK ? theme.palette.primary.main : theme.palette.secondary.main}}
        >
          <div style={{display: 'inline-flex'}}>
          <p style={{margin: 0, color: "white"}}>
          {this.props.i18n._('Classement général')}&nbsp;
          </p>
          <p style={{margin: 0, color: this.props.theme.palette.text.primary}}>
          &nbsp;{` ${ " [ " } `}&nbsp;
          </p>
          <p style={{margin: 0, color: "white"}}>
          WEEK END &nbsp; {this.props.classementClub.week_number}&nbsp;
          </p>
          <p style={{margin: 0, color: this.props.theme.palette.text.primary}}>
          {` ${ " ] " } `}&nbsp;
          </p>
          <p style={{margin: 0, color: this.props.theme.palette.text.primary}}>
              {this.props.i18n._('du').toLowerCase()}&nbsp;
              {toLocaleDateString(this.props.classementClub.selected_date)}&nbsp;
              {this.props.i18n._('au').toLowerCase()}&nbsp;
              {toLocaleDateString(moment(this.props.classementClub.selected_date).add(1,'d').toDate())}&nbsp;
          </p>
          </div>
          
        </div>
      )
    }
    
    public render(): React.ReactNode {
        const { classes, i18n } = this.props;
        return (
          <MD.Grid container style={{ width: "800px", textAlign: 'center' }} direction={'column'}>
                <Grid item style={{paddingTop: "35px", width: "800px" }}>
                  <img
                    alt="BTC"
                    src="/images/banniere-nedbrian.gif"
                  />
                  <img
                    alt="bloc-tete"
                    src="/images/bloc-tete.jpg"
                  />
                </Grid>
                
                <MD.Grid item>
                  {this.drawWeekInfos()}
                </MD.Grid> 
                { this.props.classementClub.get_classmentclub_loading ?
                    <MD.Grid style={{ paddingTop: 20 }} container direction='column'>
                      {i18n._('Chargement')}...
                    </MD.Grid>
                  : (!this.props.classementClub.get_classmentclub_loading && this.props.classementClub.classement_club_results.length === 0) ?
                    <MD.Grid style={{ paddingTop: 20 }} container direction='column'>
                      {i18n._('Pas de résultats')}
                    </MD.Grid>
                  : (!this.props.classementClub.get_classmentclub_loading && this.props.classementClub.classement_club_results.length > 0) ?
                    this.drawContent()
                  : null
                 }
            </MD.Grid>
        )
    }
  }

const mapStateToProps = ({ i18n, classementClub, layout }: IApplicationState) => ({
    i18n: i18n.i18n,
    classementClub,
    layout
});


const mapDispatchToProps = (dispatch: Dispatch) => ({
});

export const DashboardContent =
  connect(mapStateToProps, mapDispatchToProps)(MD.withTheme(MD.withStyles(styles)(DashboardContentComponent)));
