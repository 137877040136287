import moment from "moment";

export function floorMinute(d: Date) {
    if ((d.getSeconds() === 0) && (d.getMilliseconds() === 0)) {
        return d;
    }
    return new Date(
        new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            d.getHours(),
            d.getMinutes(),
            0,
            0,
        ),
    );
}

export function ceilMinute(d: Date) {
    if ((d.getSeconds() === 0) && (d.getMilliseconds() === 0)) {
        return d;
    }
    return new Date(
        new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            d.getHours(),
            d.getMinutes(),
            0,
            0,
        ).getTime() + 60000,
    );
}

export function dateFromProto(val: number | Long): Date {
    if (!(typeof val === 'number')) {
        if (
            (val.toNumber() === 0) ||
            (val.compare(-8640000000000) === -1) ||
            (val.compare(8640000000000) === 1)
        ) {
            throw new Error('Invalid date');
        }
        return new Date(val.toNumber() * 1000);
    }
    return new Date(val * 1000);
}
export function getLastWeekDay(date: Date) {
    return moment(date).subtract(7,'d').toDate()
}

export function getNextWeekDay(date: Date) {
    let new_date = new Date(date);
    return moment(new_date).add(7,'d').toDate()
}
export function toLocaleDateString(d: Date) {
    let date = new Date(d);
    return date.toLocaleDateString('fr-fr', { weekday:"long", year:"numeric", month:"long", day:"numeric"}) 
}
export function adjustForTimezone(date:Date):Date{
    var timeOffsetInMS:number = date.getTimezoneOffset() * 60000;
    
    return new Date(date.getTime() - timeOffsetInMS);
}
export function getDateHourZero(date:Date){
    return  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
}
export function fromUTC(date:Date) {
    var timeOffsetInMS:number = date.getTimezoneOffset() * 60000;
    
    return new Date(date.getTime() + timeOffsetInMS);
}
export function getDateSevenDaysBeforeTodayHourZero(todayHourZero:Date){
    let date = new Date();
    return  new Date(date.getFullYear(), date.getMonth(), todayHourZero.getDate() - 6, 0, 0, 0);
}
export function getDate2WeeksBeforeTodayHourZero(todayHourZero:Date){
    let date = new Date();
    return  new Date(date.getFullYear(), date.getMonth(), todayHourZero.getDate() - 12, 0, 0, 0);
}
